<template>
  <v-navigation-drawer :rail="!menuOpen" mobile-breakpoint="false" width="240" class="nav-drawer" rail-width="64">
    <template #prepend>
      <v-list-item style="height: 42px" class="my-3 d-flex justify-center">
        <template #prepend :class="menuOpen ? 'mr-4' : ''">
          <v-img v-if="menuOpen" :alt="organization ? organization.name : ''" class="mx-auto" :class="darkMode ? 'inverted-image' : ''" :src="logo" min-height="20" min-width="150" cover />
          <v-avatar v-else size="32" rounded="0">
            <v-img :alt="organization ? organization.name : ''" class="shrink mx-auto" :src="icon" max-width="32" cover />
          </v-avatar>
        </template>
      </v-list-item>
      <v-list-item v-if="showControlPanel" style="height: 42px">
        <v-btn v-if="menuOpen" variant="outlined" color="grey-lighten-2" class="mx-auto" size="small" width="180" @click="openControlPanel">
          <span class="text-grey-darken-1">Control Panel</span>
          <v-icon size="small" class="ml-4" color="grey-darken-1">mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn v-else variant="outlined" color="grey-lighten-2" class="mx-auto" size="small" icon @click="openControlPanel">
          <v-icon color="">mdi-account-supervisor-outline</v-icon>
        </v-btn>
      </v-list-item>
    </template>
    <v-list id="side-nav" v-model="open" density="compact" class="pt-0">
      <template v-for="item in mappedItems">
        <v-row v-if="item.heading" :key="item.heading">
          <v-col cols="6">
            <v-list-subheader v-if="item.heading" class="font-weight-bold">
              {{ item.heading }}
            </v-list-subheader>
          </v-col>
        </v-row>
        <v-divider v-else-if="item.divider" :key="item.divider" class="ma-4 mx-4"></v-divider>
        <v-list-group v-else-if="item.children" :key="item.text" :to="item.path" link>
          <template #activator="{ props }">
            <v-list-item v-bind="props" :title="item.text" class="navbar-link with-child mx-2 pa-2 rounded" :active="checkRoute(item.path)">
              <template #prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <template v-if="item.status" #append>
                <v-chip :color="item.status == 'Online' ? 'success' : 'red'" size="small" density="compact">
                  {{ item.status }}
                </v-chip>
              </template>
            </v-list-item>
          </template>
          <v-list-item v-for="(child, i) in item.children" :key="i" :value="child.text" :title="child.text" class="navbar-link mx-2 rounded" density="compact" link :to="child.path" :prepend-icon="child.icon" @click="child.click ? child.click() : () => {}"></v-list-item>
        </v-list-group>
        <v-list-item v-else :key="`${item.text}-nochild`" link :to="item.path" class="navbar-link no-child mx-2 pa-2 rounded" :active="checkRoute(item.path)" @click="item.click ? item.click() : () => {}">
          <template #prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title class="showOverflow text-body-1">
            {{ item.text }}
          </v-list-item-title>

          <template v-if="item.text == 'Messaging'" #append>
            <v-progress-circular v-if="getUnreadMessageCount == null && !menuOpen" indeterminate color="grey-lighten-2" size="22" width="2" />
            <v-avatar v-else-if="getUnreadMessageCount > 0" :color="renderBadgeColor(item)" size="22">
              <span v-if="getUnreadMessageCount < 99" class="v-badge__badge white-text" v-text="getUnreadMessageCount"></span>
              <span v-else class="v-badge__badge white-text">99+</span>
            </v-avatar>
          </template>

          <template v-if="item.status" #append>
            <v-chip :color="item.status == 'Online' ? 'success' : 'red'" size="small" density="compact">
              {{ item.status }}
            </v-chip>
          </template>
        </v-list-item>
      </template>
    </v-list>

    <template #append>
      <v-list density="compact" class="py-0">
        <v-list-item>
          <template #prepend>
            <v-btn class="float-right" icon size="x-small" variant="text" @click.stop="toggleMenu">
              <v-icon>{{ menuOpen ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
            </v-btn>
          </template>
        </v-list-item>
        <v-list-item to="/notifications" density="compact" class="mx-2pa-2 rounded">
          <template #prepend>
            <v-badge color="primary" :content="getUnreadCount" :model-value="getUnreadCount > 0" dot>
              <v-icon color="grey">mdi-bell-outline</v-icon>
            </v-badge>
          </template>
          <v-list-item-title class="text-grey">Notifications</v-list-item-title>
        </v-list-item>
        <v-list-item density="compact" class="mx-2pa-2 rounded" @click="dialog = true">
          <template #prepend>
            <v-icon color="grey">mdi-help-circle-outline</v-icon>
          </template>
          <v-list-item-title class="text-grey">Support</v-list-item-title>
        </v-list-item>
        <v-list-item :to="`/users/${encodedID}`" class="mx-2pa-2 rounded">
          <template #prepend>
            <v-icon color="grey-lighten-1">mdi-account-outline</v-icon>
          </template>
          <v-list-item-title class="text-grey">Profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <template #prepend>
            <v-icon color="grey-lighten-1">mdi-logout-variant</v-icon>
          </template>
          <v-list-item-title class="text-grey">Log Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-text class="d-flex justify-center pa-6">
          <v-container fluid class="pa-0">
            <v-row class="d-flex justify-center">
              <v-btn v-if="supportUrl" class="ma-2 px-6 text-none" variant="outlined" size="x-large" color="primary" :href="supportUrl" target="_blank" width="235px">
                <v-icon start>mdi-message-outline</v-icon>
                Contact Support
              </v-btn>
              <v-btn href="mailto:support@carelaunch.health" class="ma-2 px-6 text-none" variant="outlined" size="x-large" color="primary" width="235px">
                <v-icon start>mdi-email-outline</v-icon>
                Email Support
              </v-btn>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-btn class="ma-2 px-6 text-none" variant="outlined" size="x-large" color="primary" href="https://docs.carelaunch.health/docs/intro" target="_blank" rel="noopener noreferrer" width="235px">
                <v-icon start>mdi-file-document-outline</v-icon>
                Documentation
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container id="ccp"></v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../store/auth/mutation-types";
import { LOAD_ORGANIZATION } from "../store/organization/mutation-types";
import { LOAD_COUNTS } from "../store/messaging/mutation-types";
import { useNotificationsStore } from "@/store/notifications";

var loadMessages;
export default {
  name: "AdminDrawer",
  inject: ["checkFeature", "can"],
  data: () => ({
    dialog: false,
    org: null,
    drawer: null,
    open: [],
    dark: null,
    loading: false,
    polling: null,
    menuOpen: true,
    staticIcon: "/square.png",
    staticLogo: "/CareLaunch-Logo.png",
    items: [
      { divider: true },
      { icon: "mdi-home-outline", text: "Home", path: "/home" },
      {
        icon: "mdi-view-dashboard-outline",
        text: "Dashboard",
        path: "/dashboard",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
      {
        icon: "mdi-domain",
        text: "Organizations",
        path: "/organizations",
        feature: "organizations",
        roles: ["Orbital-Platform-Admin"],
      },
      { divider: true },
      {
        icon: "mdi-view-column-outline",
        text: "Board",
        feature: "board",
        path: "/board",
        roles: ["Orbital-Platform-Admin"],
      },
      {
        icon: "mdi-order-bool-descending-variant",
        text: "Tasks",
        feature: "tasks",
        path: "/tasks",
        roles: ["Orbital-Platform-Admin"],
      },
      {
        icon: "mdi-account-multiple-outline",
        text: "Patients",
        path: "/patients",
      },
      {
        icon: "mdi-stethoscope",
        text: "Providers",
        path: "/providers",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
      {
        icon: "mdi-account-group-outline",
        text: "Outreach",
        feature: "campaigns",
        path: "/campaigns",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
        children: [
          {
            icon: "mdi-email-multiple-outline",
            text: "Campaigns",
            path: "/campaigns/manage",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-filter-multiple-outline",
            text: "Segments",
            path: "/campaigns/segments",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            text: "Mapping",
            path: "/campaigns/mapping/attributes",
            icon: "mdi-approximately-equal",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-file-document-outline",
            text: "Templates",
            path: "/campaigns/templates",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-account-multiple-outline",
            text: "Verified Identities",
            path: "/settings/identities",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin", "Orbital-Instance-Admin"],
          },
          {
            icon: "mdi-timeline-outline",
            text: "Activity",
            path: "/campaigns/activity",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
        ],
      },
      {
        icon: "mdi-message-outline",
        text: "Messaging",
        path: "/messaging",
        feature: "messaging",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin", "Orbital-Instance-Admin"],
      },
      { divider: true },
      {
        icon: "mdi-table-arrow-left",
        text: "Data Import",
        path: "/imports",
        roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
      },
      {
        icon: "mdi-tools",
        text: "Settings",
        path: "/settings",
        roles: ["Orbital-Platform-Admin"],
        children: [
          {
            icon: "mdi-home-outline",
            text: "Organization",
            path: "/organization",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-view-agenda-outline",
            text: "Instances",
            feature: "instances",
            path: "/instances",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-account-outline",
            text: "Users",
            path: "/users",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-account-group-outline",
            text: "Custom Roles",
            feature: "custom-roles",
            path: "/settings/roles",
            roles: ["Orbital-Platform-Admin"],
          },
          {
            icon: "mdi-text-box-outline",
            text: "Custom Fields",
            path: "/settings/custom-fields",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            text: "Automations",
            path: "/settings/automations",
            feature: "automations",
            icon: "mdi-lightning-bolt-outline",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-format-list-text",
            text: "Forms",
            path: "/forms",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            text: "Tasks",
            path: "/settings/task-types",
            feature: "tasks",
            icon: "mdi-format-list-bulleted",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            icon: "mdi-swap-horizontal",
            text: "Integrations",
            path: "/integrations",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            text: "Plans",
            path: "/plans",
            icon: "mdi-map-legend",
            feature: "tasks",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
          {
            text: "Messaging",
            path: "/settings/message-templates",
            icon: "mdi-email-multiple-outline",
            feature: "messaging",
            roles: ["Orbital-Platform-Admin", "Orbital-Organization-Admin"],
          },
        ],
      },
    ],
  }),
  watch: {
    dark(val) {
      this.$vuetify.theme.dark = val;
      localStorage.setItem("dark_mode", val);
    },
  },
  async created() {
    this.menuOpen = localStorage.getItem("menuOpen") == "false" ? false : true;
    if (this.checkFeature("messaging")) {
      this.LOAD_COUNTS();
    }
    this.loading = true;
    this.loadNotificationCount();

    // Set icon and logo based on current route
    const instanceId = this.$route.params.id;
    const isInstancePage = this.$route.path.startsWith("/instances/") && instanceId;

    if (isInstancePage && this.instance?.icon) {
      this.staticIcon = this.instance.icon;
    } else if (this.organization?.instance?.icon) {
      this.staticIcon = this.organization.instance.icon;
    } else if (this.organization?.icon) {
      this.staticIcon = this.organization.icon;
    }

    if (isInstancePage && this.instance?.logo) {
      this.staticLogo = this.instance.logo;
    } else if (this.organization?.instance?.logo) {
      this.staticLogo = this.organization.instance.logo;
    } else if (this.organization?.logo) {
      this.staticLogo = this.organization.logo;
    }

    this.loading = false;
  },
  mounted() {
    this.dark = localStorage.getItem("dark_mode") == "true";
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("organization", ["organization"]),
    ...mapState("instances", ["instance"]),
    ...mapState("messaging", ["loadingConversations", "counts"]),
    ...mapGetters("messaging", ["getUnreadMessageCount"]),
    name() {
      return this.organization?.instance?.name || this.organization?.name;
    },
    encodedID() {
      return this.user ? btoa(this.user.userID) : null;
    },
    mappedItems() {
      if (!this.organization || !this.user) {
        return this.items.filter(i => {
          return !i.roles && !i.feature;
        });
      }
      return this.items
        .filter(i => {
          return !i.feature || this.checkFeature(i.feature);
        })
        .filter(i => {
          return !i.roles || this.user?.groups?.some(role => i.roles.includes(role));
        })
        .map(item => {
          return {
            ...item,
            children: item.children?.filter(i => {
              return !i.feature || this.checkFeature(i.feature);
            }),
            expanded: item.children?.find(c => this.$route.path.includes(c.path)),
          };
        });
    },
    supportUrl() {
      return this.organization.supportUrl;
    },
    icon() {
      return this.staticIcon;
    },
    logo() {
      return this.staticLogo;
    },
    showControlPanel() {
      return false;
    },
    getUnreadCount() {
      const notificationsStore = useNotificationsStore();
      return notificationsStore.getUnreadCount;
    },
    darkMode() {
      return this.$vuetify.theme.dark || this.$vuetify.theme.name == "dark";
    },
  },
  methods: {
    ...mapActions("auth", [AUTH_LOGOUT]),
    ...mapActions("messaging", [LOAD_COUNTS]),
    ...mapActions("organization", [LOAD_ORGANIZATION]),
    pollNotifications() {
      this.polling = setInterval(() => {
        if (!this.loadingNotifications) {
          this.LOAD_COUNTS();
          this.loadNotificationCount();
        }
      }, 1200000);
    },
    logout() {
      this.AUTH_LOGOUT();
    },
    loadNotificationCount() {
      const notificationsStore = useNotificationsStore();
      notificationsStore.loadNotificationCount();
    },
    renderBadgeColor(elem) {
      if (this.getUnreadMessageCount == 0 && elem.path.replace("/", "") == "messaging") return "grey lighten-1";
      return elem.path.replace("/", "") == this.$route.name ? "green" : "primary";
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      localStorage.setItem("menuOpen", this.menuOpen);
    },
    openControlPanel() {
      const width = 340;
      const height = 600;
      const features = `width=${width},height=${height},menubar=no,toolbar=no,location=no,resizable=no,scrollbars=no,status=no`;
      window.open(this.organization?.settings?.amazonConnectContactUrl ?? this.organization?.settings?.amazonConnectUrl, "_blank", features);
    },
    checkRoute(route) {
      if (route == "/") return this.$route?.path == "/";
      return this.$route?.path?.includes(route);
    },
  },
};
</script>

<style lang="css" scoped>
.v-list-item__prepend {
  margin-right: 0 !important;
  width: 40px !important;
}
</style>

<style scoped lang="scss">
#ccp {
  position: absolute;
  top: 0;
  right: 0;
  width: 500;
  height: 500;
  overflow: hidden;
}

.nav-drawer .v-list-group__items .v-list-item {
  padding-inline-start: calc(var(--indent-padding) - 38px) !important;
}

.v-navigation-drawer--rail .v-list-group__items .v-list-item {
  padding-inline-start: 16px !important;
}
</style>
