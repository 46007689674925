<template>
  <v-toolbar flat class="border-b px-2 bg-transparent">
    <span v-show="!$vuetify.display.mdAndDown" style="min-width: 125px" class="ml-4">
      <span class="text-h5 font-weight-bold">Providers</span>
    </span>
    <v-spacer></v-spacer>
    <span class="filter ma-1">
      <v-text-field v-model="userFilters.providerSearch" prepend-inner-icon="mdi-magnify" class="search-box" label="Search Name" hide-details variant="outlined" density="compact" clearable @change="updateFilters" @keydown.enter="updateFilters"></v-text-field>
    </span>
    <span class="filter ma-1">
      <v-text-field v-model="userFilters.contentSearch" prepend-inner-icon="mdi-file-search" class="search-box" label="Search Content" hide-details variant="outlined" density="compact" clearable @change="updateFilters" @keydown.enter="updateFilters"></v-text-field>
    </span>
    <span class="filter ma-1">
      <v-combobox v-model="states" :items="availableStates" label="State" multiple hide-details variant="outlined" density="compact" clearable chips @update:model-value="refresh">
        <template #chip="{ item, index }">
          <v-chip v-if="states.length < 4" color="primary" label>{{ item.title }}</v-chip>
          <v-chip v-else-if="index == 3" color="primary" label>{{ states.length }} states</v-chip>
        </template>
      </v-combobox>
    </span>
    <v-btn icon :loading="loading || syncing" @click="refresh">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <template v-if="!embedded">
      <v-btn v-if="selected.length > 0 || isDeleting" color="error" variant="outlined" :disabled="isDeleting || deleteDisabled" :loading="isDeleting || loading" class="ml-4" @click.stop="deleteDialog">Delete</v-btn>
      <v-btn v-if="selected.length > 0 || isDeleting" color="secondary" class="ml-4" variant="outlined" :loading="syncing || loading" @click="syncProviders">Sync</v-btn>
      <!-- <v-btn color="primary" @click="assignDialog = true;" class="ml-4" depressed
            :disabled="selected.length !== 1">Assign</v-btn> -->

      <v-btn v-if="selected.length == 0 && !isDeleting" color="primary" theme="dark" variant="outlined" class="ml-4 float-right" @click="addDialog = true">Import</v-btn>
      <v-btn v-if="selected.length == 0 && !isDeleting" color="primary" to="/providers/new" class="ml-4 float-right" variant="flat">New</v-btn>
    </template>
  </v-toolbar>
  <v-progress-linear v-if="loading" indeterminate color="primary" height="2"></v-progress-linear>
  <ag-grid-vue :grid-options="gridOptions" row-model-type="serverSide" :server-side-datasource="serverSideDatasource" :class="themeClass" class="rounded=0" :context="context" :column-defs="columns" :get-row-id="getRowId" @selection-changed="onSelectionChanged" @grid-ready="onGridReady"></ag-grid-vue>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="text-h5">Are you sure?</v-card-title>
      <v-card-text>{{ dialogActionText }} {{ selected.length }} providers?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" variant="flat" @click="dialogAction">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addDialog" width="80%" scrollable>
    <Import v-if="addDialog" @close-dialog="closeDialog"></Import>
  </v-dialog>
  <v-dialog v-if="assignDialog" v-model="assignDialog" min-width="1200" scrollable>
    <v-card>
      <v-toolbar variant="flat" color="primary">
        <v-icon class="mr-2" color="white">mdi-account</v-icon>
        <v-toolbar-title class="text-h6 text-white pl-0">Assign Provider</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu location="left" disabled>
          <template #activator="{ props }">
            <v-chip color="grey-lighten-2" variant="outlined" class="text-subtitle-2" v-bind="props">
              Service Request
              <v-icon end size="small">mdi-menu-down</v-icon>
            </v-chip>
          </template>
        </v-menu>
      </v-toolbar>
      <v-toolbar variant="flat" color="primary" class="px-4" height="80">
        <v-text-field v-model="assignSearch" theme="dark" label="Search" auto-grow hide-details variant="outlined" density="compact"></v-text-field>
      </v-toolbar>
      <v-progress-linear v-if="loading" indeterminate color="primary" height="2"></v-progress-linear>
      <dynamic-resource-table :resource-type="assignType" :query="getAssignQuery" height="400" :loading="loading"></dynamic-resource-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="assignDialog = false">Cancel</v-btn>
        <v-btn color="primary" variant="flat" disabled @click="assignProvider">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="js">
import { AgGridVue } from "ag-grid-vue3";
import { mapActions, mapState } from "vuex";
import { DELETE_PRACTITIONERS } from "../../store/practitioners/mutation-types";
const Import = () => import("./import.vue");

import { debounce, createCancelablePromise } from "../../utils/helpers";
import * as api from "../../utils/api";
import { USStates } from "../../utils/utils";
import ProviderNameCell from "../shared/ProviderNameCell.vue";
import RatingCell from "../shared/RatingCell.vue";
import { LOAD_USER_FILTERS, UPDATE_USER_FILTERS } from "../../store/user/mutation-types";
import { mapPractitionerBundle } from "../../utils/fhir-helpers.js";
import dynamicResourceTable from "../shared/dynamicResourceTable.vue";
import LicenseCell from "../shared/grid/LicenseCell.vue";
import { useTasks } from "../../store/tasks";

export default {
  name: "Providers",
  components: {
    AgGridVue,
    Import,
    ProviderNameCell,
    RatingCell,
    dynamicResourceTable,
    LicenseCell,
  },
  props: {
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("organization", ["organization"]),
    ...mapState("user", ["userFilters"]),
    ...mapState("patients", ["currentPatient", "isLoadingPatient"]),
    availableStates() {
      //make the object USStates iterable
      return Object.entries(USStates).map(([key, value]) => {
        return { title: value, value: key };
      });
    },
    getAssignQuery() {
      return `?status=active,draft&_text=${this.assignSearch}`;
    },
    deleteDisabled() {
      return this.selected?.some(s => s.user);
    },
    themeClass() {
      let theme = !this.embedded ? "full-height-below-nav" : "full-height";
      theme += this.$vuetify?.theme?.current?.dark ? " ag-theme-quartz-dark" : " ag-theme-quartz";
      return theme;
    },
    serverSideDatasource() {
      return {
        getRows: params => {
          this.search(params);
        },
      };
    },
    ownerStats() {
      return this.taskStore.ownerStats;
    },
  },
  data: () => ({
    USStates,
    getRowId: function (p) {
      return p?.data?.id;
    },
    taskStore: null,
    isDeleting: false,
    provider: null,
    dialog: false,
    states: [],
    practitioners: [],
    syncing: false,
    selected: [],
    dialogAction: null,
    dialogActionText: null,
    addDialog: false,
    editDialog: false,
    loading: false,
    assignDialog: false,
    assignType: "Patient",
    assignSearch: "",
    context: {
      editorUpdateMethods: {},
      editorRefs: {},
    },
    cancelableSearch: null,
    columns: [
      { headerName: "ID", field: "id", hide: true },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        hide: false,
        cellRenderer: "ProviderNameCell",
        checkboxSelection: true,
        flex: 2,
        filter: false,
      },
      { headerName: "User", field: "user", hide: true },
      {
        headerName: "Department",
        field: "extension",
        cellClass: "px-1",
        filter: false,
        valueGetter: params => {
          var d = params.data.extension?.filter(e => e.url === "department");
          return d?.map(e => e.valueString ?? e.valueCodeableConcept?.text).join(", ");
        },
      },
      {
        headerName: "Locations",
        sortable: false,
        field: "locations",
        cellClass: "px-1",
        filter: false,
      },
      {
        headerName: "States",
        field: "qualification",
        filter: false,
        width: 220,
        flex: 0,
        cellRenderer: "LicenseCell",
      },
      {
        headerName: "Open Tasks",
        field: "openTasks",
        filter: false,
        width: 130,
        flex: 0,
      },
      {
        headerName: "Tags",
        field: "meta.tag",
        cellClass: "px-1",
        hide: false,
        filter: false,
        valueGetter: params => {
          return params.data.meta?.tag?.map(e => e.code);
        },
      },
      { headerName: "Rating", field: "resource.code.text", hide: false, cellRenderer: "RatingCell", width: 130, flex: 0 },
    ],
    gridOptions: {
      pagination: true,
      paginationPageSizeSelector: false,
      paginationPageSize: 50,
      cacheBlockSize: 50, // Set the number of rows per block
      // enableCellChangeFlash: true,
      defaultColDef: {
        filter: false,
        suppressHeaderMenuButton: true,
        resizable: true,
        chartDataType: "category",
        sortable: false,
        enableRowGroup: false,
        pivot: false,
        enableValue: false,
        flex: 1,
        menuTabs: ["generalMenuTab", "columnsMenuTab"],
        cellClass: "ag-cell-task",
      },
      rowSelection: "single",
      sideBar: {
        toolPanels: ["columns", "filters"],
        defaultToolPanel: null,
      },
    },
    pages: [],
  }),
  created() {
    if (this.userFilters.hideUsers === undefined) {
      this.userFilters.hideUsers = "Non-Users";
    }
    if (this.userFilters.contentSearch === undefined) {
      this.userFilters.contentSearch = "";
    }
    this.taskStore = useTasks();
    this.taskStore.loadOwnerStats();
  },
  mounted() {
    if (this.currentPatient) {
      this.gridOptions.rowSelection = "single";
    }
    if (this.currentPatient?.state && USStates[this.currentPatient.state]) {
      this.states = [this.currentPatient.state];
    }
  },
  emits: ["update:userFilters", "close-dialog", "selection-change"],
  methods: {
    ...mapActions("practitioners", [DELETE_PRACTITIONERS]),
    ...mapActions("user", [LOAD_USER_FILTERS, UPDATE_USER_FILTERS]),
    search: debounce(function (params) {
      if (this.cancelableSearch) {
        this.cancelableSearch.cancel();
      }

      this.cancelableSearch = createCancelablePromise(async cancel => {
        this.loading = true;

        const page = this.gridApi.paginationGetCurrentPage();
        let pageToken = !page ? "" : (this.pages?.[page - 1]?.token ?? "");

        let url = "practitioner?_revinclude=PractitionerRole:practitioner&_count=" + this.gridOptions.paginationPageSize;
        if (this.userFilters.providerSearch?.length > 0) {
          url += `&name=${this.userFilters.providerSearch}`;
        }
        if (this.userFilters.contentSearch?.length > 0) {
          url += `&_content=${this.userFilters.contentSearch}`;
        }
        if (this.states?.length > 0) {
          url += `&qualification-issuer-display=${this.states.map(s => s.value).join(",")}`;
        }
        if (pageToken) url += `&_page_token=${pageToken}`;

        const sort = this.gridApi
          .getColumnState()
          ?.filter(s => s.sort !== null)
          ?.map(s => ({ colId: s.colId, sort: s.sort }));

        if (sort?.length > 0) {
          url += `&_sort=${sort[0].sort === "asc" ? "" : "-"}${sort[0].colId}`;
        }

        try {
          const response = await api.search(url, {}, true);
          if (cancel.isCancelled) return;

          if (!response) {
            this.loading = false;
            params.fail();
            return;
          }

          const results = mapPractitionerBundle(response.data);
          let rowData = results.practitioners?.map(p => {
            const practitionerTasks = this.ownerStats.filter(e => e.practitionerId === p.id);
            const openTasks = practitionerTasks.filter(e => !["completed", "cancelled", "inactive", "on-hold", "failed", "entered-in-error", "rejected"].includes(e.status)).reduce((acc, e) => acc + e.cnt, 0);
            const tasks = practitionerTasks;
            return { ...p, tasks, openTasks };
          });

          params.success({
            rowData: rowData ?? [],
            rowCount: response?.data?.total ?? 0,
          });

          this.pages[page] = {
            token: response?.data?.link?.find(l => l.relation === "next")?.url?.split("_page_token=")[1] ?? "",
          };
        } catch (error) {
          if (!cancel.isCancelled) {
            console.error("Search error:", error);
            params.fail();
          }
        } finally {
          if (!cancel.isCancelled) {
            this.loading = false;
          }
        }
      });

      this.cancelableSearch.promise.catch(() => {
        // Handle cancellation if needed
      });
    }, 500),
    updateFilters() {
      this.UPDATE_USER_FILTERS(this.userFilters);
      this.pages = [];
      if (this.cancelableSearch) {
        this.cancelableSearch.cancel();
      }
      this.refresh();
    },
    refresh() {
      if (this.cancelableSearch) {
        this.cancelableSearch.cancel();
      }
      this.gridApi.refreshServerSide({ purge: true, block: true });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      if (this.userFilters.providerSearch?.length > 0) {
        this.gridApi.updateGridOptions({ quickFilterText: this.userFilters.providerSearch });
      } else {
        this.gridApi.updateGridOptions({ quickFilterText: null });
      }
    },
    async getOwnerStatus() {
      const dat = await api.get("task/$ownerStats");
      this.ownerStats = dat?.data;
    },
    onSelectionChanged(event) {
      this.selected = event.api.getSelectedNodes().map(n => n.data);
      this.$emit("selection-change", this.selected);
    },
    deleteDialog() {
      this.dialogActionText = "Delete";
      this.dialogAction = this.deleteSelected;
      this.dialog = true;
    },
    async deleteSelected() {
      this.dialog = false;
      await this.DELETE_PRACTITIONERS(this.selected);
      this.selected = [];
    },
    closeDialog() {
      this.addDialog = false;
    },
    async syncProviders() {
      this.syncing = true;
      await Promise.all(
        this.selected.map(async p => {
          try {
            const rowNode = this.gridApi.getRowNode(p.id);
            const response = await api.post(`practitioner/${p.id}/$sync`, {}, true);
            const newData = { ...rowNode.data, ...response.data };
            rowNode.setData(newData);
          } catch (e) {
            this.$store.dispatch("SNACKBAR_ERROR", { message: e.message });
          }
        })
      );
      this.syncing = false;
      this.selected = [];
    },
    async assignProvider() {
      this.selected.map(async p => {
        try {
          const rowNode = this.gridApi.getRowNode(p.id);
        } catch (e) {
          this.$store.dispatch("SNACKBAR_ERROR", { message: e.message });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

.layout {
  display: inline-block;
  width: 100%;
}

.filter {
  width: 260px;
}

.actions {
  min-width: 240px;
  text-align: right;
}

.smallFont {
  font-size: 9pt;
}
</style>
