<template>
  <v-badge :color="ageColor" :content="daysOld" offset-y="12" offset-x="16" alt="Days Old" :model-value="daysOld > 0" class="full-width">
    <v-card variant="elevated" class="my-2 mx-3 full-width" :max-width="maxWidth" :data-id="item.id" :color="cardColor">
      <v-card-title class="pa-0">
        <v-list-item class="card-title pl-3 pr-0" slim>
          <template #prepend class="pa-0">
            <v-avatar v-if="item.logo" v-tooltip="item.instanceName" size="20">
              <failover-image v-if="item.logo" :transition="false" :disable-placeholder="true" :src="item.logo" contain max-width="20" height="20" align="start" class="mt-0 pt-0 px-0"></failover-image>
            </v-avatar>
            <v-icon v-else-if="item.icon" size="20" class="">{{ item.icon }}</v-icon>
          </template>
          <router-link v-if="item.patientID" :to="`/patients/${item.patientID}`" class="item-title my-0 cardTitle" draggable="false">
            {{ item.cardTitle }}
          </router-link>
          <span v-else class="item-title my-0 cardTitle" draggable="false">
            {{ item.cardTitle }}
          </span>
          <span v-if="item.importance == 1 ? true : false">
            <v-rating size="18" density="compact" :model-value="item.importance" color="yellow-darken-3" clearable hover length="1" readonly></v-rating>
          </span>
          <template #append>
            <v-chip class="mr-4 float-right" size="small" variant="tonal" :color="item.resourceType == 'Task' ? colorFromString(taskType?.text).hex : 'primary'" @click="clickTask(item)">
              <span class="task-type-name">{{ taskType ? taskType.text : "View" }}</span>
            </v-chip>
          </template>
        </v-list-item>
      </v-card-title>
      <v-progress-linear v-if="item.children && item.children.length > 0" :model-value="getPercentComplete" :color="getPercentComplete >= 100 ? 'success' : 'primary'" class="mx-0"></v-progress-linear>
      <v-card-subtitle v-if="item.subtitle" class="px-3">
        <v-chip v-if="item.subtitle" class="ma-0" size="x-small" variant="tonal">
          {{ item.subtitle }}
        </v-chip>
      </v-card-subtitle>
      <v-card-subtitle v-if="item.children && item.children.length > 0" class="px-0">
        <v-expansion-panels class="elevation-0 rounded-0 px-1">
          <v-expansion-panel density="compact" class="rounded-0 sub-task-title">
            <v-expansion-panel-title class="py-0 px-2 my-0">
              <v-chip v-if="item.children" class="ma-0" size="small" variant="text" color="primary">
                {{ subTaskText }}
              </v-chip>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="px-0">
              <v-list density="compact" class="sub-task-list">
                <template v-for="(subtask, index) in item.children" :key="index">
                  <v-list-item density="compact" class="sub-task-title pa-0" height="36">
                    <template #append>
                      <v-progress-circular v-if="subtask.updating" indeterminate :size="18" width="2" color="grey"></v-progress-circular>
                      <Avatar v-else-if="subtask.assigneeID != null && subtask.owner?.display" v-tooltip:start="subtask.owner?.display" start size="20px" :user="subtask.owner"></Avatar>
                      <template v-else-if="subtask.assigneeID != null && (user = findUser(subtask.assigneeID))">
                        <Avatar v-if="user" v-tooltip:start="renderUserName(user)" start size="20px" :user="user"></Avatar>
                      </template>
                    </template>
                    <template #prepend="{ isActive }">
                      <v-checkbox v-model="subtask.done" :loading="subtask.updating" :disabled="!canEdit" @update:model-value="toggleSubTaskStatus(subtask)"></v-checkbox>
                    </template>
                    <template #default>
                      <span class="cursor-pointer" :class="{ 'text-no-wrap': true, 'text-decoration-line-through': subtask.done }" @click="clickTask(subtask)">{{ subtask.description ? subtask.description : subtask.code ? subtask.code.text : "-" }}</span>
                    </template>
                  </v-list-item>
                </template>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-subtitle>
      <v-card-text v-if="item.date && showDate" class="py-0">
        <span class="text-caption">{{ formatDate(item.date) }}</span>
      </v-card-text>
      <v-card-actions class="overflow-hidden pt-2 card-actions mr-0 pr-0" @click.stop>
        <template v-if="!hide">
          <v-menu location="bottom" theme="dark" :disabled="!canEdit && (!taskStatuses || taskStatuses.length == 0)">
            <template v-if="statusField == 'business-status' && item.plan" #activator="{ props }">
              <v-chip :color="item.updating ? 'grey lighten-2' : 'grey'" variant="outlined" v-bind="props" size="small" class="status-chip">
                <span class="status-name">{{ item.businessStatus ? item.businessStatus.text : "-" }}</span>
                <v-icon v-if="!disabled" end size="small">mdi-menu-down</v-icon>
              </v-chip>
            </template>
            <template v-else #activator="{ props }">
              <v-chip :color="item.updating ? 'grey lighten-2' : 'grey'" variant="text" v-bind="props" size="small" class="status-chip">
                <span class="status-name">{{ mapStatus(item.status) }}</span>
              </v-chip>
            </template>
            <v-list v-if="taskStatuses.length > 0" density="compact">
              <v-list-item v-for="(status, index) in taskStatuses" :key="index" :class="item.status == status.value ? 'grey lighten-2' : ''" @click="updateStatus(status)">
                <template v-if="status.icon" #prepend>
                  <v-icon v-if="status.icon" size="28">
                    {{ status.icon }}
                  </v-icon>
                </template>
                {{ status.text }}
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-if="item.tags">
            <v-chip v-for="(tag, index) in item.tags.slice(0, 3)" :key="`${item.name}-${index}`" size="small" variant="outlined" class="mr-2" :color="!colorFromString(tag.code) ? '' : colorFromString(tag.code).name + ' text-' + colorFromString(tag.code).text">
              <span style="max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis !important">{{ tag.code }}</span>
            </v-chip>
            <v-chip v-if="item.tags.length > 3" size="small" class="mr-2 mb-1" color="grey"><span>More</span></v-chip>
          </span>
          <span class="float-right text-no-wrap text-right" style="margin-left: auto">
            <span v-if="taskAge !== null" v-tooltip:start="taskAge + ' days since last status update'" class="task-age mx-2 text-no-wrap">
              <v-chip :color="taskAge > 4 ? 'error' : taskAge > 3 ? 'warning' : 'primary'" theme="dark" size="x-small">{{ taskAge }}</v-chip>
            </span>
            <v-chip v-if="agingDate" v-tooltip:start="'Created: ' + formatDate(agingDate)" class="mr-2" size="x-small" variant="tonal">
              {{ formatShortDate(agingDate) }}
            </v-chip>
            <span class="text-caption text-right" @click.stop="clickTask(item)">
              <v-progress-circular v-if="item.updating" indeterminate :size="18" width="2" color="grey" class="mr-2"></v-progress-circular>
              <v-chip v-else class="assignee-chip" pill size="x-small" :variant="assignee ? 'text' : 'plain'">
                <template v-if="assignee">
                  <Avatar start size="20px" :user="assignee"></Avatar>
                  <span class="chip-name">{{ renderName(assignee) }}</span>
                </template>
                <v-icon v-else size="24px" color="grey-lighten-2">mdi-account</v-icon>
              </v-chip>
            </span>
          </span>
        </template>
      </v-card-actions>
    </v-card>
  </v-badge>
</template>

<script>
import * as fhirHelper from "../../utils/fhir-helpers.js";
import { formatDate, formatShortDate, colorFromString } from "../../utils/helpers";
import PractitionerSelector from "../shared/practitionerSelector.vue";
import { mapState, mapGetters } from "vuex";
import { useTasks } from "../../store/tasks";
import { useFhir } from "../../store/fhir/index.js";

export default {
  name: "Card",
  components: {
    PractitionerSelector,
  },
  model: {
    prop: "item",
  },
  props: {
    item: { type: Object, default: () => {} },
    agingField: {
      type: String,
      default: null,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    possibleAssignees: {
      type: Array,
      default: () => [],
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    statusField: {
      type: String,
      default: "status",
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [String, Number],
      default: "290",
    },
  },
  emits: ["updateAssignee", "updateStatus", "checkBoxChanged", "cardClick"],
  data: () => ({
    hide: false,
    assignDialog: false,
    newAssignee: null,
    percentComplete: 0,
    fhirStore: null,
    taskStore: null,
  }),
  created() {
    this.fhirStore = useFhir();
    this.taskStore = useTasks();
  },
  computed: {
    ...mapState("user", { users: "users" }),
    ...mapState("organization", { organization: "organization" }),
    ...mapGetters("user", ["getAdminsByInstance", "getAdminUsers"]),
    mappedUsers() {
      let users = this.possibleAssignees;

      return [
        {
          title: "Un-Assigned",
          id: null,
        },
      ].concat(users);
    },
    daysOld() {
      //if we have an aging field being passed, lets check for any extensions with that code and get the value
      if (this.agingField && this.item.extension?.find(e => e.url == this.agingField)) {
        let age = this.item.extension?.find(e => e.url == this.agingField);
        if (!age) return this.item.age;
        let value = age.valueDateTime ?? age.valueString ?? age.valueDate;
        if (value) {
          let date = new Date(value);
          let now = new Date();
          let diff = now - date;
          return Math.floor(diff / (1000 * 60 * 60 * 24));
        }
      }
      return this.item.age;
    },
    agingDate() {
      if (this.agingField && this.item.extension?.find(e => e.url == this.agingField)) {
        let age = this.item.extension?.find(e => e.url == this.agingField);
        if (!age) return this.item.age;
        let value = age.valueDateTime ?? age.valueString ?? age.valueDate;
        if (value) {
          return value;
        }
      }
      return this.item.authoredOn;
    },
    assignee() {
      return this.item.assigneeID ? this.findUser(this.item.assigneeID) : null;
    },
    ageColor() {
      return this.daysOld >= 7 ? "error" : this.daysOld > 3 ? "warning" : "grey";
    },
    taskType() {
      //find the type based on the item.code
      return this.taskStore.taskTypes.find(t => t.value == this.item.code?.text);
    },
    taskAge() {
      //check the statusReason.text for the date
      if (this.item.statusReason?.text) {
        //return the number of days since the date
        let date = new Date(this.item.statusReason.text);
        let now = new Date();
        let diff = now - date;
        return Math.floor(diff / (1000 * 60 * 60 * 24));
      }
      return null;
    },
    subTaskText() {
      //find the total that are not done
      let total = this.item.children?.length;
      let done = this.item.children?.filter(c => c?.status == "completed")?.length;
      return `${done} of ${total} Sub-Tasks`;
    },
    taskStatuses() {
      return (
        this.item.plan?.action?.map(a => {
          return { value: a, text: toProperCase(a.title.replaceAll("-", " ")) };
        }) ?? this.statuses
      );
    },
    getPercentComplete() {
      this.updatePercentComplete();
      return this.percentComplete;
    },
    cardColor() {
      if (this.taskType?.text) {
        return this.item.partOf ? colorFromString(this.taskType?.text).hex : "white";
      }
      return null;
    },
  },
  methods: {
    colorFromString,
    formatDate,
    formatShortDate,
    renderName(person) {
      let name = person.display ?? person.title ?? fhirHelper.renderName(person.name);
      //return first name
      return name.split(" ")[0];
    },
    mapStatus(value) {
      const status = this.statuses.find(s => value == s.value || s.values?.includes(value));
      if (!status) return toProperCase(value);
      return status.text;
    },
    updateStatus(status) {
      this.$emit("updateStatus", this.item, status);
    },
    updatePercentComplete() {
      if (!this.item) return;
      const total = this.item?.children?.length;
      const done = this.item.children?.filter(subtask => subtask.status == "completed").length;
      this.percentComplete = Math.round((done / total) * 100);
    },
    toggleSubTaskStatus(subTask) {
      let subTaskPlan = this.fhirStore?.activePlans.find(p => p.id === subTask.basedOn?.[0]?.reference?.split("/")[1]);
      if (subTask.done) {
        subTask.status = "completed";
      } else {
        subTask.status = "requested";
      }
      let newStatus = subTask.status;
      if (subTaskPlan?.action?.length > 0) {
        newStatus = subTaskPlan.action?.[0]?.title;
        if (subTask.done) {
          newStatus = subTaskPlan.action?.[subTaskPlan.action.length - 1]?.title;
        }
      }
      subTask.updating = true;
      subTask.businessStatus = { text: newStatus, coding: [{ code: newStatus }] };
      this.updatePercentComplete();

      this.$emit("checkBoxChanged", subTask);
    },
    clickTask(task) {
      if (task.updating) return;
      this.$emit("cardClick", task);
    },
    findUser(id) {
      return this.mappedUsers?.find(p => p?.id == id);
    },
    renderUserName(user) {
      return user?.name?.[0]?.text ?? user?.name;
    },
  },
};
</script>

<style scoped>
.card-actions {
  min-height: 46px !important;
}

.v-card .v-card-title {
  line-height: 1.5rem;
}

.card-title {
  width: 100%;
}

.card-title .v-list-item__prepend {
  padding: 0;
}

.sub-task-title .v-expansion-panel-title {
  min-height: 32px;
  padding: 0 16px;
  line-height: 32px;
  height: 32px;
  font-size: 13px;
}
</style>
