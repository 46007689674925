import * as types from "./mutation-types";
import { actions } from "./actions";

const ERROR_MESSAGE = "An error has occured. Please verify your input and try again.";

function initialState() {
  return {
    appointment: {
      description: null,
      status: "proposed",
      participant: [],
      comment: null,
    },
    appointments: null,
    patientAppointments: null,
    proposedAppointments: null,
    organizations: null,
    patients: null,
    practitioners: null,
    error: null,
    processing: false,
    loading: false,
    dirty: false,
  };
}

export const mutations = {
  [types.CREATE_APPOINTMENT]: (state, appointment) => {
    state.processing = true;
    state.appointment = appointment;
    state.error = null;
  },
  [types.APPOINTMENT_CREATED]: (state, appt) => {
    state.appointment = appt;
    if (state.patientAppointments?.length == 0 || state.patientAppointments?.find(pAppt => pAppt.resource.participant?.find(p => p.actor.reference == appt.participant[0].actor.reference)))
      state.patientAppointments.unshift({ resource: appt });
    state.processing = false;
  },
  [types.APPOINTMENT_CREATED_FAILURE]: (state, error) => {
    state.processing = false;
    state.alertText = ERROR_MESSAGE;
  },
  [types.UPDATE_APPOINTMENT]: state => {
    state.processing = true;
    state.error = null;
  },
  [types.UPDATE_APPOINTMENT_SUCCESS]: (state, appointment) => {
    if (state.appointments && state.appointments.length > 0) {
      let index = state.appointments.findIndex(a => a.resource.id == appointment.id);
      if (index > -1) {
        state.appointments[index].resource = appointment;
      }
    }
    state.processing = false;
    state.error = null;
    state.dirty = true;
  },
  [types.UPDATE_APPOINTMENT_FAILURE]: (state, error) => {
    state.processing = false;
    state.error = error;
  },
  [types.LOAD_PATIENT_APPOINTMENTS]: (state, error) => {
    state.error = error;
  },
  [types.LOAD_PATIENT_APPOINTMENTS_COMPLETE]: (state, { appointments, practitioners }) => {
    state.isLoadingAppointments = false;
    state.patientAppointments = appointments;
    state.practitioners = practitioners;
    state.error = null;
    state.processing = false;
  },
  [types.LOAD_PATIENT_APPOINTMENTS_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.FIND_APPOINTMENTS]: state => {
    state.error = null;
  },
  [types.FIND_APPOINTMENTS_SUCCESS]: (state, proposedAppointments) => {
    state.proposedAppointments = proposedAppointments;
  },
  [types.FIND_APPOINTMENTS_FAILURE]: state => {
    state.error = null;
  },
  [types.RESET_STATE]: state => {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  [types.DELETE_APPOINTMENT]: state => {
    state.error = null;
  },
  [types.DELETE_APPOINTMENT_SUCCESS]: state => {
    state.error = null;
  },
  [types.DELETE_APPOINTMENT_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.SET_CURRENT_APPOINTMENT]: (state, appointment) => {
    state.appointment = appointment;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
