<template>
  <v-card variant="flat" color="transparent" v-bind="$attrs" class="full-width">
    <v-card-title class="text-subtitle-2 px-0 pb-0">
      <v-list-item class="pa-0 ma-0">
        <v-btn variant="plain" prepend-icon="mdi-check" class="header-btn" :ripple="false">
          Tasks
          <v-chip :v-if="taskCount > 0" inline variant="tonal" size="small" class="ml-2">{{ taskCount }}</v-chip>
        </v-btn>
        <template #append>
          <v-btn icon="mdi-refresh" variant="text" class="float-right" size="small" color="primary" @click="loadTasks()"></v-btn>
          <v-btn v-if="canEdit" icon="mdi-plus" variant="text" class="float-right" size="small" color="primary" @click="showTaskDialog()"></v-btn>
        </template>
      </v-list-item>
    </v-card-title>
    <v-card-text subheader expand class="bg-transparent pa-0" :style="`max-height: ${bodyHeight}px; overflow-y: auto;`">
      <v-progress-linear v-if="loadingTasks" indeterminate color="primary"></v-progress-linear>
      <v-list v-else-if="mappedTasks.length == 0" class="bg-transparent">
        <v-list-item class="bg-transparent">No tasks found</v-list-item>
      </v-list>
      <v-card v-for="i in 10" v-if="loadingTasks && mappedTasks?.length == 0" :key="i" height="110" class="mx-3 mr-9 mb-4">
        <v-skeleton-loader loading type="list-item"></v-skeleton-loader>
      </v-card>
      <card v-for="(item, i) in mappedTasks" :key="item.id" :item="item" class="" :can-edit="canEdit" :possible-assignees="possibleOwners" :status-field="item.plan != null ? 'business-status' : 'status'" @update-status="updateTaskStatus" @card-click="showTaskDialog" @check-box-changed="checkBoxChanged"></card>
    </v-card-text>
  </v-card>
  <v-dialog v-if="taskFormDialog" v-model="taskFormDialog" width="auto" max-width="60%" persistent>
    <v-form ref="form" v-model="valid" autocomplete="off" :disabled="processing">
      <v-card>
        <v-card-title class="card-title" admin-appbar>
          <span class="text-h6">{{ formView.task.focus.display }}</span>
          <v-spacer />
          <v-btn variant="flat" class="mr-2" icon @click="cancelFormView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Render :form="formView.form" :response-id="formView.responseID" :patient="currentPatient" :review="formView.completed" class="formRender" @next-step="completeTask(formView.task)"></Render>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
  <v-dialog v-if="taskDialog" v-model="taskDialog" width="auto" @click:outside="cancelTaskEdit">
    <TaskEdit :task="task" :possible-assignees="possibleOwners" :parent-task="parentTask" :allow-delete="admin" :available-forms="availableForms" :can-edit="canEdit" :error="errorMessage" @show-task="showTask" @task-update="taskUpdate" @close-dialog="cancelTaskEdit"></TaskEdit>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { INIT_ESIGNATURE, RESUME_ESIGNATURE, DOWNLOAD_ESIGNATURE } from "../../store/integrations/mutation-types";
import { LOAD_ADMIN_USERS } from "../../store/user/mutation-types";
import * as fhirHelper from "../../utils/fhir-helpers.js";
import Render from "../forms/render.vue";
import Card from "../board/Card.vue";
import TaskEdit from "./TaskEdit.vue";

import { formatDateTime, toProperCase, formatDate } from "../../utils/helpers";
import { useFhir } from "../../store/fhir/index.js";
import { useTasks } from "../../store/tasks";

export default {
  name: "TaskList",
  components: { Render, Card },
  inheritAttrs: false,
  props: {
    subject: {
      type: String,
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    owner: {
      type: String,
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    availableForms: {
      type: Array,
      default: () => [],
    },
    currentForms: {
      type: Array,
      default: () => [],
    },
    bodyHeight: {
      type: Number,
      default: 500,
    },
    hideComplete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const fhirStore = useFhir();
    const taskStore = useTasks();
    return {
      fhirStore,
      taskStore,
      taskDialog: false,
      taskFormDialog: false,
      newTask: {
        id: null,
        code: {
          text: "Task",
          coding: [
            {
              code: "task",
            },
          ],
        },
        description: null,
        note: null,
        businessStatus: null,
        intent: "proposal",
        priority: "routine",
        status: "requested",
        focus: {
          reference: null,
          display: null,
        },
        for: {
          reference: null,
          display: null,
        },
        owner: {
          reference: null,
          display: null,
        },
        requester: {
          reference: null,
          display: null,
        },
        authoredOn: null,
      },
      task: null,
      valid: true,
      selectedForm: null,
      formView: null,
      assignee: null,
      errorMessage: null,
      rules: {
        required: value => {
          if (Array.isArray(value) && value?.length == 0) return "Required";
          return !!value || "Required";
        },
      },
    };
  },
  watch: {
    assignee(val) {
      if (val) {
        this.task.owner = {
          reference: val == "Patient" ? this.subject : "Practitioner/" + val,
          display: val == "Patient" ? this.subjectName : this.possibleOwners.find(o => o?.id == val)?.text,
        };
      }
    },
  },
  computed: {
    ...mapState("user", ["users", "roles"]),
    ...mapState("auth", ["user"]),
    ...mapState("patients", ["currentPatient"]),
    ...mapState("practitioners", { practitioners: "practitioners", loadingPractitioners: "loading" }),
    ...mapGetters("user", ["getAdminsByInstance", "getAdminUsers"]),
    ...mapState("instances", ["instances"]),
    assignedProviders() {
      return this.taskStore.assignedProviders ?? [];
    },
    admin() {
      return this.user?.groups?.includes("Orbital-Platform-Admin");
    },
    taskTypes() {
      return this.taskStore.taskTypes;
    },
    taskCount() {
      return this.mappedTasks.length;
    },
    tasks() {
      return this.taskStore.tasks;
    },
    parentTask() {
      return this.taskStore.tasks.find(t => t.id === this.task.partOf?.[0]?.reference?.replace("Task/", ""));
    },
    possibleOwners() {
      let users = this.getAdminUsers
        ?.filter(u => u.practitionerID)
        ?.map(user => {
          let practitioner = this.practitioners?.find(p => p.id === user.practitionerID);
          let name = fhirHelper.renderName(practitioner?.name) ?? user?.name;
          return {
            profileImage: user.profileImage,
            id: user.practitionerID,
            name: name,
            title: name,
            userID: user.userID,
          };
        })
        .sort((a, b) => {
          if (a.title && b.title) return a.title?.localeCompare(b.title);

          return 0;
        });
      //loop through the assignedProviders and add them to the list
      if (this.assignedProviders) {
        this.assignedProviders.forEach(provider => {
          let practitioner = this.users?.find(p => p.id === provider.id);
          if (!practitioner) {
            let name = fhirHelper.renderName(provider?.name);
            users.push({
              id: provider.id,
              practitionerID: provider.id,
              title: name,
              name: name,
            });
          }
        });
      }
      if (this.practitioners) {
        const userMap = new Map(users.map(user => [user.id, user]));
        this.practitioners.forEach(provider => {
          //check if already in the list
          if (!userMap.has(provider.id)) {
            let name = fhirHelper.renderName(provider?.name);
            users.push({
              id: provider.id,
              title: name,
              name: name,
            });
          }
        });
      }

      //lets bump ourselves to the top of the list
      let me = users.find(u => u.userID === this.user.userID);
      users = users.filter(u => u.userID !== this.user.userID);

      return [
        // {
        //   id: "Patient",
        //   name: "Patient",
        //   title: "Patient",
        // },
        me,
        ...users,
      ];
    },
    loadingTasks() {
      return this.taskStore.loading;
    },
    subTasks() {
      return this.taskStore.tasks
        ?.filter(t => t.partOf)
        ?.map(task => {
          let plan = task.basedOn?.[0]?.reference ? this.fhirStore.getResourceById("PlanDefinition", task.basedOn?.[0]?.reference?.replace("PlanDefinition/", "")) : null;
          return {
            ...task,
            plan,
          };
        });
    },
    mappedTasks() {
      return (
        this.taskStore.tasks
          ?.filter(task => {
            if (task.partOf === undefined || !task.partOf) return true;
            return task.partOf[0].reference.replace("Task/", "") === task.id;
          })
          .map(task => {
            let childTasks = this.subTasks.filter(t => t.partOf?.[0]?.reference.replace("Task/", "") === task.id).map(c => fhirHelper.mapTaskToVM(c, this.practitioners, this.instances));
            let plan = task.basedOn?.[0]?.reference ? this.fhirStore.getResourceById("PlanDefinition", task.basedOn?.[0]?.reference?.replace("PlanDefinition/", "")) : null;
            return {
              ...fhirHelper.mapTaskToVM(task, this.practitioners),
              subTitle: task.patient?.display,
              children: childTasks.sort((a, b) => new Date(a.authoredOn) - new Date(b.authoredOn)),
              plan,
            };
          }) ?? []
      );
    },
  },
  created() {
    this.fhirStore.loadResources("PlanDefinition");
    this.taskStore.resetTasks();
    this.loadTasks();
    if (this.taskTypes.length == 0) this.taskStore.loadTaskTypes();
    if (this.taskStore.getCustomFields.length == 0) this.taskStore.loadCustomFields();
  },
  methods: {
    formatDateTime,
    formatDate,
    toProperCase,
    ...mapActions("integrations", [INIT_ESIGNATURE, RESUME_ESIGNATURE, DOWNLOAD_ESIGNATURE]),
    ...mapActions("user", [LOAD_ADMIN_USERS]),
    async loadTasks() {
      await this.taskStore.loadTasks({ subject: this.subject, owner: this.owner, "status:not": this.hideComplete ? ["completed", "failed", "cancelled"] : null });
    },
    renderName(person) {
      return fhirHelper.renderName(person.name);
    },
    timeChanged(time) {
      if (!this.task.restriction) this.task.restriction = {};

      //combine this.task.dueDate and this.task.dueTime
      let date = new Date(this.task.dueDate);
      let timeParts = this.task.dueTime.split(":");
      date.setHours(timeParts[0]);
      date.setMinutes(timeParts[1]);
      date.setSeconds(timeParts[2]);
      time = date.toISOString();

      this.task.restriction.start = time;
    },
    showTaskDialog(task) {
      if (!task?.id) {
        let plan = this.fhirStore.defaultPlan;
        this.newTask.status = "requested";
        this.newTask.basedOn = [{ reference: "PlanDefinition/" + plan?.id, display: plan?.title }];
        this.newTask.businessStatus = { text: plan?.action?.[0]?.title };
        this.newTask.code = this.newTask.code ?? {};
        this.newTask.code.text = this.taskStore.defaultTaskType;
        this.newTask.code.coding = [{ code: this.taskStore.defaultTaskType }];

        this.newTask.for = {
          reference: this.subject,
          display: this.subjectName,
        };
        this.newTask.owner = {
          reference: "Practitioner/" + this.user.practitionerID,
          display: this.user.name,
        };
        this.newTask.requester = {
          reference: "Practitioner/" + this.user.practitionerID,
          display: this.user.name,
        };
        this.newTask.authoredOn = new Date().toISOString();
        this.taskDialog = true;
        this.task = task
          ? Object.assign(
              { ...this.newTask },
              this.taskStore.tasks.find(t => t.id === task.id)
            )
          : { ...this.newTask };
      } else {
        this.task = { ...task };
      }

      this.assignee = task ? (task.owner?.reference?.indexOf("Patient") > -1 ? "Patient" : task.owner?.reference?.replace("Practitioner/", "")) : this.user.practitionerID;
      if (task?.id && task.focus?.reference) {
        let taskForm = this.currentForms?.find(f => task.focus?.reference.indexOf(f.key) > -1)?.form;
        this.selectedForm = this.availableForms.find(f => f.questionnaireID === taskForm?.questionnaireID);
      }

      this.taskDialog = true;
    },
    findTaskById(id) {
      return this.mappedTasks.find(t => t.id === id);
    },
    cancelTaskEdit() {
      this.taskDialog = false;
      this.selectedForm = null;
    },
    cancelFormView() {
      this.taskFormDialog = false;
      this.selectedForm = null;
    },
    deleteTask(task) {
      this.taskDialog = false;
      this.taskStore.deleteTask(task);
    },
    async checkBoxChanged(task) {
      task = updateTaskStatusReason(task);
      await this.taskStore.updateTask(task);
    },
    completeTask(task) {
      task.status = "completed";
      this.taskStore.updateTask(task);
      this.taskFormDialog = false;
    },
    updateTaskStatus(task, status) {
      task.businessStatus = {
        text: status.text,
        coding: [
          {
            system: "TaskPlanStatus",
            code: status.text,
            display: status.text,
          },
        ],
      };
      task = updateTaskStatusReason(task);
      this.taskStore.updateTask(task);
    },
    deleteSubtask(task) {
      this.taskStore.deleteTask(task);
    },
    showTask(task) {
      let localTask = this.mappedTasks.find(t => t.id === task.id) ?? this.subTasks.find(t => t.id === task.id);
      this.showTaskDialog(localTask);
    },
    viewTask(task) {
      if (task.code?.text === "eSignature") {
        if (task.status === "completed") {
          this.DOWNLOAD_ESIGNATURE({ req: { taskID: task.id, name: `${task.description}.pdf` } });
        } else {
          this.RESUME_ESIGNATURE({
            req: {
              taskID: task.id,
              envelopeID: task.identifier[0].value,
              signerClientId: this.currentPatient.id,
              signerEmail: this.user.id,
              signerName: this.user.name,
              returnTo: "p",
            },
            redirect: true,
          });
        }
      }
      if (task?.code?.text === "Questionnaire") {
        let taskForm = this.currentForms?.find(f => task.focus?.reference.indexOf(f.key) > -1);
        let form = this.availableForms.find(f => f.questionnaireID === taskForm?.form?.questionnaireID);
        this.formView = { responseID: task.focus.reference, form: form, task: task, completed: taskForm?.status === "completed" };
        this.taskFormDialog = true;
      }
    },
    async taskUpdate(updatedTask) {
      try {
        this.errorMessage = null;

        // Input validation
        if (!updatedTask) {
          this.errorMessage = "Invalid task data";
          return false;
        }

        if (!updatedTask.status || !["requested", "accepted", "rejected", "completed"].includes(updatedTask.status)) {
          this.errorMessage = "Invalid task status";
          return false;
        }

        // Create a copy to avoid mutations
        const taskToUpdate = { ...updatedTask };

        // Ensure required fields
        if (!taskToUpdate.owner?.reference) {
          this.errorMessage = "Task must have an owner";
          return false;
        }

        // Wait for the API response
        let response;
        try {
          response = await this.taskStore.updateTask(taskToUpdate);
        } catch (apiError) {
          console.error("API call failed:", apiError);
          this.errorMessage = apiError?.message || "Failed to communicate with server";
          return false;
        }

        // Detailed error checking
        if (!response) {
          this.errorMessage = "No response received from server";
          return false;
        }

        if (response.error) {
          this.errorMessage = response.error.message || "Failed to save task";
          return false;
        }

        if (!response.success) {
          this.errorMessage = "Task update was not successful";
          return false;
        }

        // Only proceed with UI updates if save was successful
        try {
          // Update main task
          const index = this.mappedTasks.findIndex(t => t.id === taskToUpdate.id);
          if (index !== -1) {
            // Create new task object with all properties
            const newTask = {
              ...this.mappedTasks[index],
              ...taskToUpdate,
              lastUpdated: new Date().toISOString(),
            };
            this.$set(this.mappedTasks, index, newTask);
          } else {
            this.mappedTasks.push({
              ...taskToUpdate,
              lastUpdated: new Date().toISOString(),
            });
          }

          // Update subtasks if necessary
          if (taskToUpdate.children?.length > 0) {
            taskToUpdate.children.forEach(childTask => {
              if (!childTask?.id) return; // Skip invalid children

              const childIndex = this.subTasks.findIndex(t => t.id === childTask.id);
              if (childIndex !== -1) {
                // Use Vue's $set for reactive updates
                this.$set(this.subTasks, childIndex, {
                  ...this.subTasks[childIndex],
                  ...childTask,
                  lastUpdated: new Date().toISOString(),
                });
              } else {
                this.subTasks.push({
                  ...childTask,
                  lastUpdated: new Date().toISOString(),
                });
              }
            });
          }

          // Only close dialog and reload if everything succeeded
          this.taskDialog = false;
          await this.loadTasks();
          return true;
        } catch (uiError) {
          console.error("UI update error:", uiError);
          this.errorMessage = "Error updating UI - please refresh the page";
          return false;
        }
      } catch (error) {
        console.error("Task update error:", error);
        this.errorMessage = error?.message || "An unexpected error occurred";
        return false;
      }
    },
  },
};
</script>
