<template>
  <div>
    <div class="d-flex justify-space-between align-center full-width menubar pa-2">
      <span v-for="actionName in activeButtons" :key="actionName">
        <v-btn v-if="actionName === 'bold'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('bold') }" tabindex="1" @click.stop="editor.chain().focus().toggleBold().run()">
          <v-icon size="small">mdi-format-bold</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'italic'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('italic') }" tabindex="1" @click.stop="editor.chain().focus().toggleItalic().run()">
          <v-icon size="small">mdi-format-italic</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'strike'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('strike') }" tabindex="1" @click.stop="editor.chain().focus().toggleStrike().run()">
          <v-icon size="small">mdi-format-strikethrough</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'underline'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('underline') }" tabindex="1" @click.stop="editor.chain().focus().toggleUnderline().run()">
          <v-icon size="small">mdi-format-underline</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'code'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('code') }" tabindex="1" @click.stop="editor.chain().focus().toggleCode().run()">
          <v-icon size="small">mdi-code-tags</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'h1'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" tabindex="1" @click.stop="editor.chain().focus().toggleHeading({ level: 1 }).run()">H1</v-btn>
        <!-- <v-btn v-if="actionName === 'h2'" class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click.stop="editor.chain().focus().toggleHeading({ level: 2 }).run()">
          H2
        </v-btn >
        <v-btn v-if="actionName === 'h3'" class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click.stop="editor.chain().focus().toggleHeading({ level: 3 }).run()">
          H3
        </v-btn > -->
        <v-btn v-if="actionName === 'bulletList'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('bulletList') }" tabindex="1" @click.stop="editor.chain().focus().toggleBulletList().run()">
          <v-icon size="small">mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'orderedList'" variant="text" density="compact" class="menubar__button" :class="{ 'is-active': editor.isActive('orderedList') }" tabindex="1" @click.stop="editor.chain().focus().toggleOrderedList().run()">
          <v-icon size="small">mdi-format-list-numbered</v-icon>
        </v-btn>
        <!-- <v-btn v-if="actionName === 'blockquote'" class="menubar__button"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          @click.stop="editor.chain().focus().toggleBlockquote().run()">
          <v-icon size="small">mdi-format-quote-close</v-icon>
        </v-btn >
        <v-btn v-if="actionName === 'codeBlock'" class="menubar__button"
          :class="{ 'is-active': editor.isActive('codeBlock') }" @click.stop="editor.chain().focus().toggleCodeBlock().run()">
          <v-icon size="small">mdi-code-braces</v-icon>
        </v-btn > -->
        <v-btn v-if="actionName === 'horizontalRule'" variant="text" density="compact" class="menubar__button" tabindex="1" @click.stop="editor.chain().focus().setHorizontalRule().run()">
          <v-icon size="small">mdi-minus</v-icon>
        </v-btn>
        <!-- <v-btn v-if="actionName === 'undo'" class="menubar__button" @click.stop="editor.chain().focus().undo().run()">
          <v-icon size="small">mdi-undo</v-icon>
        </v-btn >
        <v-btn v-if="actionName === 'redo'" class="menubar__button" @click.stop="editor.chain().focus().redo().run()">
          <v-icon size="small">mdi-redo</v-icon>
        </v-btn > -->
        <v-btn
          v-if="actionName === 'link'"
          variant="text"
          icon
          density="compact"
          class="menubar__button"
          :class="{
            'bg-box-transparent text-primary': editor.isActive('link'),
          }"
          tabindex="1"
          title="Link"
          @click.stop="setLink(editor)">
          <v-icon size="small">mdi-link</v-icon>
        </v-btn>
        <v-btn v-if="actionName === 'unlink'" v-show="editor.isActive('link')" variant="text" density="compact" class="menubar__button" title="Remove link" tabindex="1" @click.stop="editor.commands.unsetLink()">
          <v-icon size="small">mdi-link-off</v-icon>
        </v-btn>
      </span>
      <template v-if="templates && templates.length > 0">
        <v-tooltip location="bottom" tabindex="1">
          <template #activator="{ props }">
            <v-btn class="menubar__v-btn pt-0 mt-2" v-bind="props" tabindex="1" variant="text" density="compact" icon>
              <v-menu location="right">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="pa-0" size="16">mdi-text-box-outline</v-icon>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in templates" :key="index" @click.stop="insertTemplate(item)">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </template>
          <span>Insert Template</span>
        </v-tooltip>
      </template>
    </div>
    <editor-content class="editor__content" :editor="editor" :placeholder="placeholder" />
  </div>
</template>
<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";

export default {
  name: "Editor",
  components: {
    EditorContent,
  },
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    templates: {
      type: Array,
      default: null,
    },
    templateVariables: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Enter text here...",
    },
    activeButtons: {
      type: Array,
      validator: function (list) {
        for (let el of list) {
          // The value must match one of these strings
          if (["bold", "italic", "strike", "underline", "code", "h1", "h2", "h3", "bulletList", "orderedList", "blockquote", "codeBlock", "horizontalRule", "undo", "redo", "link"].indexOf(el) === -1) {
            return -1;
          }
        }
        return 1;
      },
      default: () => ["bold", "italic", "strike", "underline", "code", "h1", "h2", "h3", "bulletList", "orderedList", "horizontalRule", "undo", "redo", "link"],
    },
  },
  emits: ["update"],
  data() {
    return {
      html: "",
      json: "",
      editor: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        if (value !== this.html) {
          this.editor.commands.setContent(value);
        }
      },
      deep: true,
    },
  },
  created() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit, Underline, Link],
    });
    this.html = this.editor.getHTML();
    this.json = this.editor.getJSON();
    this.editor.on("update", () => {
      this.html = this.editor.getHTML();
      this.json = this.editor.getJSON();
      this.update();
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    update() {
      this.$emit("update", this.html);
    },
    insertTemplate(template) {
      let message = template.message;
      for (let key in this.templateVariables) {
        if (Object.prototype.hasOwnProperty.call(this.templateVariables, key)) message = message.replace(new RegExp(`${key}`, "g"), this.templateVariables[key]);
      }
      this.editor.commands.insertContent(message);
    },
    setLink() {
      const url = prompt("URL");
      if (url) {
        this.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
      }
    },
  },
};
</script>
<style lang="css">
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ol {
  padding: 0 1rem;
  list-style: decimal !important;
}

.ProseMirror ul {
  padding: 0 1rem;
  list-style: disc !important;
}
</style>
<style lang="css">
.editor {
  display: flex;
  flex-direction: column;
  background-color: inherit;
  /* border: 1px solid #ccc; */
}

.editor__content {
  flex: 1 1 auto;
  padding: 10px;
  outline: none;
}

.menubar {
  display: flex;
  flex-wrap: wrap;
  /* border-bottom: 1px solid #ccc; */
}

.menubar__v-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}

.menubar__button:hover {
  background: #eee;
}

.menubar__v-btn + .menubar__v-btn {
  border-left: 1px solid #ccc;
}

.menubar__button.is-active {
  background: #eee;
}

/* remove outline */
.ProseMirror:focus {
  outline: none;
}

/* set */
.ProseMirror {
  min-height: 80px;
  overflow: scroll;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>
