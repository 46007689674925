import { actions } from "./actions";
import * as types from "./mutation-types";
import appointmentstatuses from "../../assets/code-systems/appointmentstatus.json";
const automationTemplate = {
  name: null,
  description: null,
  trigger: { type: "Manual", processResource: 'report' },
  actions: [],
  filters: [],
  active: false,
  query: null,
};
const state = {
  automations: [],
  automation: { ...automationTemplate },
  error: null,
  loading: false,
  processing: false,
  eventTypes: [
    { name: "Manual" },
    // { name: "Scheduled", disabled: true },
    { name: "Scheduled (Recurring)" },
    { name: "Resource Creation", action: "create" },
    { name: "Resource Update", action: "update" },
    { name: "Resource Delete", action: "delete" },
  ],
  actionTypes: [
    { name: "Create Resource", type: "fhir", resourceTemplate: {} },
    { 
      name: "Process Event Entry", 
      type: "event",
      resourceTemplate: {
        statusFilter: "Initial",
        regexFilter: ".*",
        description: "Process event entries matching filters"
      }
    },
    {
      name: "Create Task", type: "task", templateType: "Task", resourceTemplate: {
        resourceType: "Task",
        // status: "requested",
        comment: null,
        description: null,
        code: { "coding": [{ "system": "CareLaunchTaskType", "code": "Task", "display": "Task" }], "text": "Task" },
      },
    },
    { name: "Create Task - Connect", type: "amazonconnect", templateType: "Task" },
    { name: "Create Task - Zendesk", type: "Zendesk", templateType: "Task" },
    { name: "Send to Survey Monkey", type: "SurveyMonkey", templateType: "Email" },
    { name: "Add to Campaigns", type: "campaign" },
    { name: "Update Campaigns", type: "campaign" },
    { name: "Send Secure Message", type: "message", templateType: "Secure Message" },
    { name: "Sync Integration - Incoming", type: "integration", resourceTemplate: {} },
    { name: "Sync Integration - Outgoing", type: "integration", resourceTemplate: {} },
    // { name: "Send SMS", type: "message", templateType: "SMS", disabled: true },
    // {
    //   name: "Create Appointment Opportunity",
    //   type: "appointment",
    //   resourceTemplate: {
    //     resourceType: "Appointment",
    //     status: "proposed",
    //     comment: null,
    //     description: null,
    //     serviceType: {
    //       coding: [],
    //     },
    //   },
    //   disabled: true
    // },
    // {
    //   name: "Send Email", type: "message", templateType: "Email", disabled: true,
    //   resourceTemplate: {
    //     email: {
    //       messageConfig: {
    //         fromAddress: null,
    //       },
    //     }
    //   },
    // },
    {
      name: "Assign Form",
      templateType: "Email",
      type: "task",
      resourceTemplate: {
        resourceType: "Task",
        status: "proposed",
        comment: null,
        description: null,
        code: { coding: [] },
      },
      disabled: true
    }
  ],
  filterTypes: [
    { source: "ServiceRequest", type: "code", field: "code" },
    { source: "ServiceRequest", type: "code", field: "category" },
    { source: "ServiceRequest", type: "date", field: "authoredOn", display: "Authored On" },
    // { source: "ServiceRequest", type: "reference", field: "performer" },
    // { source: "ServiceRequest", type: "code", field: "performerType" },
    // { source: "ServiceRequest", type: "reference", field: "requestor" },
    {
      source: "ServiceRequest",
      type: "enum",
      field: "status",
      values: [
        { name: "Active", value: "active" },
        { name: "Draft", value: "draft" },
        { name: "Completed", value: "completed" },
        { name: "On Hold", value: "on-hold" },
        { name: "Entered in Error", value: "entered-in-error" },
      ],
    },
    // { source: "ServiceRequest", type: "reference", field: "organization" },
    {
      source: "Appointment",
      type: "enum",
      field: "status",
      values: appointmentstatuses.concept.map(s => {
        return { name: s.display, value: s.code };
      }),
    },
  ],
  comparisons: [
    { fieldType: "code", text: "equals" }, //, querySuffix: ":contains" },
    { fieldType: "enum", text: "equals" },
    { fieldType: "date", text: "is before", queryPrefix: "lt" },
    { fieldType: "date", text: "is after", queryPrefix: "gt" },
    { fieldType: "number", text: "is equal to" },
    { fieldType: "number", text: "is greater than", queryPrefix: "gt" },
    { fieldType: "number", text: "is less than", queryPrefix: "lt" },
  ],
};

export const mutations = {
  [types.LOAD_AUTOMATIONS]: state => {
    state.error = null;
    state.loading = true;
  },
  [types.LOAD_AUTOMATIONS_SUCCESS]: (state, automations) => {
    state.automations = automations;
    state.loading = false;
  },
  [types.LOAD_AUTOMATIONS_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.LOAD_AUTOMATION_BY_ID]: state => {
    state.loading = true;
    state.automation = {};
  },
  [types.LOAD_AUTOMATION_BY_ID_SUCCESS]: (state, automation) => {
    state.automation = automation;
    state.automation.actions.forEach(a => {
      if (typeof a.resourceTemplate === "string") {
        a.resourceTemplate = JSON.parse(a.resourceTemplate);
      }
    });

    state.loading = false;
  },
  [types.LOAD_AUTOMATION_BY_ID_FAILURE]: (state, error) => {
    state.error = error;
    state.loading = false;
  },
  [types.CREATE_AUTOMATION]: state => {
    state.processing = true;
  },
  [types.CREATE_AUTOMATION_SUCCESS]: (state, automation) => {
    state.processing = false;
    state.automation = automation;
  },
  [types.CREATE_AUTOMATION_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.UPDATE_AUTOMATION]: state => {
    state.processing = true;
  },
  [types.UPDATE_AUTOMATION_SUCCESS]: state => {
    state.processing = false;
  },
  [types.UPDATE_AUTOMATION_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.DELETE_AUTOMATION]: state => {
    state.processing = true;
  },
  [types.DELETE_AUTOMATION_SUCCESS]: (state, id) => {
    state.automations = state.automations.filter(r => r.id !== id);
    state.processing = false;
  },
  [types.DELETE_AUTOMATION_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.RESET_AUTOMATION]: state => {
    state.automation = { ...automationTemplate };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
