<template>
  <v-stepper v-model="step" class="pa-0 elevation-0 fill-height overflow-auto" flat>
    <v-card width="auto" class="fill-height overflow-auto" min-width="600">
      <v-card-title v-if="patient && !complete" class="border-b px-4 pt-4">
        <v-stepper-header class="elevation-0">
          <template v-for="s in mappedSteps" :key="s.name">
            <v-stepper-item :complete="step > s.step" :step="s.step" :editable="patient.id ? true : false" :title="s.name" :value="s.step" edit-icon="mdi-check" @click="step = s.step"></v-stepper-item>
          </template>
        </v-stepper-header>
      </v-card-title>
      <v-card-text class="pa-2 modal-card-text">
        <div v-if="complete" class="mt-8 pt-8 d-flex align-center justify-center">
          <span class="text-center">
            <v-icon size="120" color="primary">mdi-check-circle-outline</v-icon>
            <br />
            <span class="text-h5 mt-4">Complete</span>
          </span>
        </div>
        <v-form v-if="!complete && patient" ref="form" v-model="valid" autocomplete="off" :disabled="processing" class="fill-height overflow-auto">
          <v-stepper-window style="min-height: 420px" class="fill-height overflow-auto">
            <v-stepper-window-item :value="1" class="ma-0">
              <v-alert v-if="duplicatePatient" prominent variant="text" type="info" class="px-8 py-0 mx-4 my-0">
                <a :href="'patients/' + duplicatePatient.id">This patient might already exist</a>
              </v-alert>
              <v-row class="pt-2">
                <v-col cols="12" md="6">
                  <instance-selector v-if="checkFeature('instances') && !instanceAdmin && user?.groups" label="Instance" :value="patient.instanceID" class="mb-4" item-value="id" clearable @update="(patient.instanceID = $event), renderForms()"></instance-selector>
                  <CountrySelector :value="patient.location" :bypass-restrictions="true" class="mb-4 mt-0" @update="locationChange"></CountrySelector>

                  <v-row v-for="(name, i) in patient.name" :key="i" no-gutters>
                    <v-col cols="12" xl="6">
                      <v-text-field v-if="name.given" id="first-name" v-bind="$attrs" v-model.trim="name.given[0]" variant="outlined" label="First Name*" class="mr-2 mb-4" @update:model-value="$emit('update', value)"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="6">
                      <v-text-field id="last-name" v-bind="$attrs" v-model.trim="name.family" variant="outlined" label="Last Name*" class="mb-4" @update:model-value="$emit('update', value)"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field v-model="patient.dob" label="Date of Birth" variant="outlined" :rules="[rules.dob]" autocomplete="off" clearable type="date" class="mb-4"></v-text-field>
                  <v-select v-model="patient.gender" :items="genders" :rules="[rules.required]" label="Gender*" clearable required variant="outlined" class="mt-4"></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="patient.email" :rules="[rules.email, emailValidation]" label="E-mail*" clearable required variant="outlined" autocomplete="off" validate-on="blur" hide-details class="mb-4" @change="CHECK_USERNAME_AVAILABILITY(patient.email)"></v-text-field>
                  <v-text-field v-model.trim="patient.phone" :rules="[rules.phone]" label="Phone" variant="outlined" clearable class="mb-4" placeholder="Format: +1234567890"></v-text-field>
                  <v-text-field v-if="patient.country == 'US' && requireSSN" v-model.trim="patient.ssn" :rules="[rules.checkssn]" label="SSN*" type="SSN" clearable required variant="outlined" autocomplete="false" placeholder="111-11-1111" validate-on="blur"></v-text-field>
                  <v-text-field v-model="address.street" :rules="[]" label="Street Address" variant="outlined" clearable class="mb-4"></v-text-field>
                  <v-row no-gutters>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="address.city" :rules="[]" label="City" variant="outlined" clearable class="mr-2"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" xl="3">
                      <v-text-field v-model="address.state" :rules="[]" label="State" variant="outlined" class="mr-2 mb-4"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" xl="3">
                      <v-text-field v-model="address.postalCode" :rules="[rules.postalCode]" label="ZIP Code" variant="outlined"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-window-item>
            <template v-for="(s, index) in mappedSteps" :key="`${s.name}-content`">
              <v-stepper-window-item :step="s.step" :value="`form-${index}`" class="ma-0 pa-0 fill-height overflow-auto">
                <Render v-if="s.form && patient?.id && step == index + 1" ref="forms" populate-form :form="s.form" :patient="patient" :review="false" class="formRender my-0 py-0" style="max-height: 590px; overflow-y: auto" hide-controls @is-form-valid="handleIsInnerFormValid"></Render>
                <Upload v-if="step == index + 1 && s.name == 'Upload' && patient.id" :patient="patient" :hide-buttons="true" :hide-esign="true" class="mnh-500" />
              </v-stepper-window-item>
            </template>
          </v-stepper-window>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn v-if="complete" variant="flat" @click="closeDialog(patientToRedirect)">Close</v-btn>
        <span v-else>
          <v-btn v-if="debug" icon>
            <v-icon color="grey" @click="randomize">mdi-dice-3-outline</v-icon>
          </v-btn>
          <v-btn variant="text" class="mr-2" @click="closeDialog(patient)">Cancel</v-btn>
          <v-btn color="primary" :disabled="!ready" :loading="stepping || processing || formProcessing" variant="flat" @click="nextStep">Continue</v-btn>
        </span>
      </v-card-actions>
    </v-card>
  </v-stepper>
</template>

<script lang="js">
import { formatDate, checkEmail } from "../../utils/helpers.js";
import { mapState, mapActions } from "vuex";
import { CHECK_USERNAME_AVAILABILITY } from "../../store/user/mutation-types";
import { CREATE_PATIENT, UPDATE_PATIENT, LOAD_COUNTRIES, RESET_STATE } from "../../store/register/mutation-types";
import { LOAD_INSTANCES } from "../../store/instances/mutation-types";
import { FORM_LOAD_BY_ID } from "../../store/forms/mutation-types";
import { SET_PATIENT, LOAD_PATIENT_BY_ID } from "../../store/patients/mutation-types";
import Render from "../forms/render.vue";
import SharedAddress from "../shared/address.vue";
import Name from "../../components/shared/name.vue";
import CountrySelector from "../../components/shared/countrySelector.vue";
import Phone from "../shared/phone.vue";
import Upload from "./upload.vue";
import * as api from "../../utils/api.js";

export default {
  name: "Index",
  components: { Render, SharedAddress, Name, CountrySelector, Phone, Upload },
  inject: ["checkFeature"],
  emits: ["close-dialog"],
  data: () => ({
    valid: false,
    address: { address: null, street: null, city: null, state: null, postalCode: null },
    date: null,
    dateFormatted: null,
    dobMenu: false,
    instanceID: null,
    instanceAdmin: false,
    stepping: false,
    complete: false,
    rules: {
      required: value => {
        if (value && typeof value === "string") return !!value.trim() || "Required.";
        else return !!value || "Required.";
      },
      phone: value => {
        if (!value) return true;
        const phonePattern = /^\+?1?\d{9,15}$/;
        return phonePattern.test(value?.replace(/\D/g, "")) || "Invalid phone number format";
      },
      email: value => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value) || "Invalid email format";
      },
      postalCode: value => {
        return true;
      },
      checkssn: value => {
        if (this.patient.country == "US" && this.instance && this.instance.requireSSN) {
          const pattern = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;
          return pattern.test(value) || "Invalid SSN";
        } else {
          return true;
        }
      },
      dob: value => {
        if (!value) return true;
        var validDate = true;
        try {
          var date = new Date(value);
          if (isNaN(date.getTime())) validDate = "Invalid date";
        } catch {
          return false;
        }
        var dateFilter = new Date();
        dateFilter.setDate(dateFilter.getDate() - 365.25 * 16);
        if (new Date(value) > dateFilter) {
          return "We are sorry, but at this time you must be at least 16 years old to use this service";
        }
        return validDate;
      },
    },
    genders: [
      { title: "Female", value: "female" },
      { title: "Male", value: "male" },
      { title: "Other", value: "other" },
    ],
    formSteps: [],
    step: 1,
    duplicatePatient: null,
    patientToRedirect: "",
  }),
  watch: {
    date(val) {
      var date = formatDate(val);
      if (date) {
        this.dateFormatted = date;
        this.patient.dob = val;
      }
    },
    address: {
      handler: function (val) {
        this.patient.address = val.address;
        this.patient.street = val.street;
        if (val.country) this.patient.country = val.country;
        this.patient.city = val.city;
        this.patient.state = val.state;
        this.patient.postalCode = val.postalCode;
      },
      deep: true,
    },
    "patient.name": {
      handler: function () {
        if (!this.patient) return;
        this.patient.name.forEach(n => {
          n.text = (n.given ? n.given.toString() : "") + (n.family ? " " + n.family.toString() : "");
        });
        this.findDuplicatePatient();
      },
      deep: true,
    },
  },
  async created() {
    if (!this.user) return;
    this.patient.location = "US";
    if (this.countries?.length == 0 && !this.loadingCountries) this.LOAD_COUNTRIES();
    if (!this.instances?.length > 0) await this.LOAD_INSTANCES();
    this.instanceAdmin = this.user?.groups?.some(g => g == "Orbital-Instance-Admin");
    this.instanceID = this.user.instanceID || this.instance?.id || this.instances?.[0].id;
    this.patient.instanceID = this.instanceID;
    if (this.patient?.instanceID) this.renderForms();
  },
  computed: {
    ...mapState("register", ["patient", "processing", "countries", "loadingCountries"]),
    ...mapState("user", ["validatingPhone", "phoneValid", "userNameAvailable"]),
    ...mapState("organization", ["organization"]),
    ...mapState("instances", ["instances"]),
    ...mapState("organization", ["organization"]),
    ...mapState("patients", { formProcessing: "processing" }),
    ...mapState("auth", ["user"]),
    ...mapState("forms", ["forms", "form"]),
    debug() {
      return this.organization?.debug || process.env.NODE_ENV === "development";
    },
    ready() {
      if (!this.valid) return false;
      if (this.stepping || this.processing || this.formProcessing) return false;
      return this.patient.name[0].text && this.patient.email;
    },
    requireSSN() {
      if (this.patient.instanceID) {
        var currentInstance = this.instances.find(i => i.id == this.patient.instanceID);
        return currentInstance?.requireSSN;
      } else {
        return false;
      }
    },
    emailValidation() {
      return this.userNameAvailable ? true : "This email is already in use";
    },
    mappedInstances() {
      return this.user?.groups ? this.instances : null;
    },
    mappedSteps() {
      let steps = [
        {
          name: "Demographics",
          next: () => {
            this.createPatient();
          },
        },
      ];
      if (this.formSteps.length > 0) {
        steps = steps.concat(this.formSteps);
      }
      steps.push({
        name: "Upload",
      });
      return steps.map((s, index) => {
        return { ...s, step: index + 1 };
      });
    },
  },
  methods: {
    checkEmail,
    ...mapActions("register", [CREATE_PATIENT, UPDATE_PATIENT, LOAD_COUNTRIES, RESET_STATE]),
    ...mapActions("user", [CHECK_USERNAME_AVAILABILITY]),
    ...mapActions("instances", [LOAD_INSTANCES]),
    ...mapActions("forms", [FORM_LOAD_BY_ID]),
    ...mapActions("patients", [SET_PATIENT, LOAD_PATIENT_BY_ID]),
    validate() {
      this.$refs.form.validate();
    },
    async closeDialog(patient) {
      this.RESET_STATE();
      this.$emit("close-dialog", patient);
    },
    async randomize() {
      let { patient } = await api.randomPatient();
      if (!this.patient.instanceID) this.patient.instanceID = this.instances[0].id;
      if (!this.patient.name?.[0].text) this.patient.name = patient.name;
      this.patient.location ??= patient.address?.[0]?.country;
      if (!this.patient.email) this.patient.email = patient.telecom?.find(({ system }) => system === "email")?.value;
      if (!this.patient.phone) this.patient.phone = "+14254147755"; //Pinpoint test number
      if (!this.patient.address) this.patient.address = patient.address;
      if (!this.patient.dob) this.patient.dob = patient.birthDate;
      this.dateFormatted = this.patient.dob;
      if (!this.patient.ssn && this.patient.country === "US") this.patient.ssn = "000000000";
      this.address = { address: null, street: null, city: null, state: null, postalCode: null };
      this.address.address = this.patient.address;
      this.address.street = patient.address?.[0]?.line?.[0];
      this.address.city = patient.address?.[0]?.city;
      this.address.state = patient.address?.[0]?.state;
      this.address.postalCode = patient.address?.[0]?.postalCode;

      await this.renderForms();
    },
    checkCountry(event) {
      if (event) {
        this.patient.country = event.code;
        if (event.local) {
          this.countryDialog = true;
          this.valid = false;
        }
      }
    },
    parseDate(val) {
      if (val) {
        var date = formatDate(val);
        if (date) {
          this.dateFormatted = date;
          this.patient.dob = date;
        }
      }
    },
    async createPatient() {
      this.patient.instanceName = this.instances.find(i => i.id == this.patient.instanceID)?.name;
      if (!this.patient.id) {
        await this.CREATE_PATIENT(this.patient);
        this.patient.phone = this.patient.countryCode + " " + this.patient.phone;
      } else {
        await this.UPDATE_PATIENT(this.patient);
      }
    },
    async renderForms() {
      this.formSteps = [];
      let instance = this.instances.find(i => i.id === this.patient.instanceID);
      if (instance?.forms) {
        for (let index = 0; index < instance.forms.length; index++) {
          let formData = await api.get(`admin/form/${instance.forms[index]}`);
          let form = formData.data;
          if (form) {
            this.formSteps.push({
              name: form.name,
              form: form,
            });
          }
        }
      }
    },
    nextStep() {
      this.stepping = true;
      let currentStep = this.mappedSteps[this.step - 1];
      if (currentStep?.next) currentStep.next();

      if (this.$refs.forms) {
        this.$refs.forms.forEach(async form => {
          await form.saveResponses();
        });
      }
      if (this.mappedSteps.length == this.step) {
        this.patientToRedirect = this.patient;
      }
      this.step++;
      this.complete = this.step > this.mappedSteps.length;
      this.stepping = false;
    },

    findDuplicatePatient() {
      if (this.patients) {
        var match = this.patients.find(p => p.name == this.patient.name[0].text);
        this.duplicatePatient = match;
      }
    },
    locationChange(val) {
      this.patient.location = val;
    },
    handleIsInnerFormValid(val) {
      this.valid = val;
    },
  },
};
</script>
