<template>
  <div class="fill-height">
    <div v-if="filesInProgress.length > 0">
      <v-card v-for="file in filesInProgress" :key="file.data.id" class="mb-3 py-2" variant="outlined">
        <v-list-item>
          <template #prepend color="primary-lighten-1">
            <v-icon v-if="file.data.type == 'application/x-zip-compressed'" color="white" class="mx-auto">mdi-folder-zip-outline</v-icon>
            <v-icon v-else-if="file.data.type == 'application/pdf'" color="white" class="mx-auto">mdi-file-pdf-outline</v-icon>
            <v-icon v-else-if="file.data.type && file.data.type.indexOf('image') > -1" color="white" class="mx-auto">mdi-image</v-icon>
            <v-icon v-else color="white" class="mx-auto">mdi-file</v-icon>
          </template>
          <v-list-item-title class="text-subtitle-2 text-left" :class="file.error ? 'text-red' : ''">
            {{ file.data.description }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption text-left mb-0 text-grey" :class="file.error ? 'text-red' : ''">
            <v-progress-linear v-if="file.progress < 100 || file.processing" v-model="file.progress" align="start" class="my-2 mx-0" height="20" :color="file.error ? 'red' : 'primary'">
              <strong v-if="file.error">Error</strong>
              <strong v-else-if="file.progress === 100" class="text-white">Processing...</strong>
              <strong v-else-if="file.progress" :class="file.progress > 48 ? 'text-white' : ''">{{ file.progress }}%</strong>
            </v-progress-linear>
            {{ file.data.name }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-card>
    </div>
    <div v-else class="drop-box">
      <v-card variant="flat">
        <v-card-text class="pa-1">
          <v-row justify="space-around">
            <input id="fileUpload" type="file" accept="*" class="input-file" @change="uploadFile($event)" />
            <v-icon v-if="$vuetify.display.mdAndUp" size="large" color="grey-lighten-1" class="mt-10">mdi-clipboard-text-outline</v-icon>
          </v-row>
          <v-row v-if="!$vuetify.display.mdAndUp" justify="space-around" class="mt-4 text-h6">
            <p>
              <a>Browse</a>
            </p>
          </v-row>
          <v-row v-if="$vuetify.display.mdAndUp" justify="space-around" class="my-6">
            <p>
              Drag and Drop or
              <a>Browse</a>
            </p>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="descriptionDialog" persistent contained>
      <v-card>
        <v-card-title class="font-weight-bold">Please provide a description</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="fileDescription" label="File Description" :rules="[rules.required]" variant="outlined" @keydown.enter.prevent="descriptionDialogCallback"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          {{ descriptionDialogFilename }}
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" color="primary" variant="flat" @click="descriptionDialogCallback">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as api from "../../utils/api.js";
import * as fhirHelper from "../../utils/fhir-helpers";
import { mapState, mapActions } from "vuex";
import { CREATE_DOCUMENT, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_COMPLETE } from "../../store/patients/mutation-types";
import { SNACKBAR_ERROR, SLOW_CONNECTION, SLOW_CONNECTION_RESOLVED } from "../../store/appMutations";
export default {
  name: "PatientFileUpload",
  components: {},
  beforeRouteLeave(to, from, next) {
    this.descriptionDialog = false;
  },
  props: ["patientID"],
  data: () => ({
    valid: false,
    fileDescription: null,
    descriptionDialog: false,
    descriptionDialogFilename: null,
    descriptionDialogCallback: null,
    rules: {
      required: value => !!value || "Required.",
    },
    filesToUpload: [],
  }),
  async created() {
    // if (!this.patientID) throw new Error("PatientFileUpload requires a patientID prop");
  },
  computed: {
    ...mapState("register", ["error", "instanceID"]),
    ...mapState("instances", ["instance", "loading"]),
    filesInProgress() {
      return this.filesToUpload.filter(f => f.inProgress || f.processing);
    },
    currentInstanceID() {
      return this.instance?.id || this.instanceID;
    },
  },
  methods: {
    ...mapActions("patients", [CREATE_DOCUMENT, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_COMPLETE]),
    ...mapActions([SNACKBAR_ERROR, SLOW_CONNECTION, SLOW_CONNECTION_RESOLVED]),
    async upload(file) {
      this.CREATE_DOCUMENT();
      let fileData = file.data;
      let formData = new FormData();
      formData.append("file", fileData);
      file.inProgress = true;
      file.processing = true;
      const instanceHeader = { "instance-id": this.currentInstanceID };
      const patientHeader = { patientID: this.patientID };
      const parent = this;
      try {
        const binary = await api.createBinary(
          formData,
          {
            headers: { ...instanceHeader, ...patientHeader },
            onUploadProgress: function (progressEvent) {
              file.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              const rate = progressEvent.loaded - file.prevLoaded;
              const minRate = 100000;
              if (!file.slowConnection && file.prevLoaded > minRate && rate < minRate) {
                file.slowConnection = true;
                parent.SLOW_CONNECTION();
              } else if (rate >= minRate) {
                file.slowConnection = false;
                if (!parent.filesToUpload.filter(f => f.slowConnection)) parent.SLOW_CONNECTION_RESOLVED();
              }
              file.prevLoaded = progressEvent.loaded;
            },
          },
          { timeout: 600000 }
        );
        fileData.binaryID = binary.data.id;
        var docRef = fhirHelper.createDocumentReference(fileData, this.patientID, this.currentInstanceID);
        var options = {
          headers: { ...instanceHeader },
        };
        const doc = await api.post("DocumentReference", docRef, options);
        file.inProgress = false;
        file.processing = false;
        this.filesToUpload = this.filesToUpload.filter(f => f.key !== file.key);
        if (!this.fileToUpload || !this.fileToUpload.filter(f => f.slowConnection)) {
          this.SLOW_CONNECTION_RESOLVED();
        }
        this.CREATE_DOCUMENT_COMPLETE(doc);
        this.$emit("close-dialog");
      } catch (error) {
        file.error = true;
        var msg = "A file failed to upload. Please check your connection and try again.";
        if (error.response) msg = error.response?.data;
        this.SNACKBAR_ERROR(msg);
        this.CREATE_DOCUMENT_FAILURE();
      }
    },
    async uploadFile(event) {
      let file = event.target.files[0];
      let parent = this;
      this.descriptionDialogFilename = file.name;
      this.fileDescription = file.name;
      this.descriptionDialogCallback = async function () {
        if (parent.$refs.form.validate()) {
          file.description = parent.fileDescription;
          let fileToUpload = { data: file, inProgress: false, progress: 0, processing: false, error: false, key: new Date(), prevLoaded: 0, slowConnection: false };
          parent.filesToUpload.push(fileToUpload);
          parent.upload(fileToUpload);
          parent.descriptionDialog = false;
          let fileUpload = document.getElementById("fileUpload");
          if (fileUpload) fileUpload.value = null;
          parent.descriptionDialogFilename = null;
          parent.$refs.form.reset();
        }
      };
      this.descriptionDialog = true;
    },
  },
};
</script>
<style scoped>
.drop-box {
  border: 3px dashed rgba(25, 118, 210, 0.11) !important;
  border-radius: 4px;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.input-file-small {
  height: 100px;
}
</style>
