import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as fhirHelper from "../../utils/fhir-helpers";
import * as appMutations from "../appMutations";

export const actions = {
  [types.CREATE_APPOINTMENT]: async ({ commit, dispatch, state }, appointment) => {
    commit(types.CREATE_APPOINTMENT, appointment);
    try {
      var options = {
        headers: { "instance-id": state.instanceID },
      };
      var fhirAppointment = fhirHelper.createFhirAppointment(appointment);
      var resp = await api.createAppointment(fhirAppointment, options);
      commit(types.APPOINTMENT_CREATED, resp.data);
    } catch (error) {
      commit(types.APPOINTMENT_CREATED_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, error?.response?.data ?? "Error Creating Appointment", {
        root: true,
      });
      throw error;
    }
  },
  [types.SET_CURRENT_APPOINTMENT]: async ({ commit }, appointment) => {
    commit(types.SET_CURRENT_APPOINTMENT, appointment);
  },
  [types.UPDATE_APPOINTMENT]: async ({ commit, dispatch, state }, appointment) => {
    commit(types.UPDATE_APPOINTMENT);
    try {
      const response = await api.client.put(`/Appointment/${appointment.id}`, appointment);
      commit(types.UPDATE_APPOINTMENT_SUCCESS, response?.data);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Appointment Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_APPOINTMENT_FAILURE, error);
      // dispatch(types.LOAD_APPOINTMENTS, { force: true });
      dispatch(appMutations.SNACKBAR_ERROR, error?.response?.data, {
        root: true,
      });
    }
  },
  [types.LOAD_PATIENT_APPOINTMENTS]: async ({ commit, state, dispatch }, patientID) => {
    commit(types.LOAD_PATIENT_APPOINTMENTS);
    try {
      var options = {
        headers: {
          "instance-id": state.instanceID ? state.instanceID : localStorage.getItem("instanceID"),
        },
      };
      const response = await api.fetchAppointments(patientID, options);
      const appointments = response?.data.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "Appointment") : [];
      const practitioners = response?.data.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "Practitioner") : [];
      commit(types.LOAD_PATIENT_APPOINTMENTS_COMPLETE, { appointments: appointments, practitioners: practitioners });
    } catch (error) {
      commit(types.LOAD_PATIENT_APPOINTMENTS_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Appointments", {
        root: true,
      });
      throw error;
    }
  },
  [types.FIND_APPOINTMENTS]: async ({ commit, state, dispatch }, { providerID, departmentIDs, visitTypeIDs, startDate, endDate }) => {
    commit(types.FIND_APPOINTMENTS);
    try {
      const instanceID = state.instanceID ? state.instanceID : localStorage.getItem("instanceID"); //TODO: this isnt correct
      if (!providerID) {
        const providerResponse = await api.search("GetSchedulingProvider?instance=" + instanceID, {});
        let provider = providerResponse?.data;
        providerID = provider.providerID;
        visitTypeIDs = provider.visitTypeIDs;
        departmentIDs = provider.departmentIDs;
      }
      const params = fhirHelper.findAppointmentParams(providerID, departmentIDs, visitTypeIDs, startDate, endDate);
      const options = {
        headers: {
          "instance-id": state.instanceID ? state.instanceID : localStorage.getItem("instanceID"),
        },
      };
      const response = await api.post("appointment/$find", params, options);
      commit(types.FIND_APPOINTMENTS_SUCCESS, response);
    } catch (error) {
      console.log(error);
      commit(types.FIND_APPOINTMENTS_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Proposed Appointments", {
        root: true,
      });
    }
  },
  [types.DELETE_APPOINTMENT]: async ({ commit, dispatch, state }, appointment) => {
    commit(types.DELETE_APPOINTMENT);
    try {
      await api.deleteResource("appointment/" + appointment.id);
      commit(types.DELETE_APPOINTMENT_SUCCESS);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Appointment Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_APPOINTMENT_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Appointment", {
        root: true,
      });
    }
  },
  [types.RESET_STATE]: ({ commit }) => {
    commit(types.RESET_STATE);
  },
};
