<template>
  <v-row v-if="patientInstance.uploadInstructions" justify="center" class="mx-auto mb-8 ql-editor overflow-auto upload-body">
    <v-col>
      <span v-html="patientInstance.uploadInstructions"></span>
    </v-col>
  </v-row>
  <instance-required-files v-if="patientInstance && patientInstance.files && patientInstance.files.length > 0" :patient-instance="patientInstance" @init-signature="initSignature"></instance-required-files>
  <patient-files v-if="patient && patient.id" :patient-i-d="patient.id" :allow-download-all="false"></patient-files>
  <patient-file-upload v-if="patient && patient.id" :patient-i-d="patient.id" class="mnh-300 fill-height"></patient-file-upload>
  <v-row v-if="!hideButtons" class="pa-4" justify="center" align="center">
    <v-btn variant="text" size="large" rounded class="mr-3" @click="previousStep">Previous</v-btn>
    <v-btn id="submit" size="large" color="primary" rounded variant="flat" class="my-10" :disabled="getHasActiveUploads" :loading="getHasActiveUploads" @click="complete">Continue</v-btn>
  </v-row>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { INIT_ESIGNATURE, RESUME_ESIGNATURE } from "../../store/integrations/mutation-types";
import InstanceRequiredFiles from "../shared/instanceRequiredFiles.vue";
import PatientFiles from "../shared/patientFiles.vue";
import PatientFileUpload from "../shared/patientFileUpload.vue";

export default {
  name: "Upload",
  components: { PatientFiles, InstanceRequiredFiles, PatientFileUpload },
  props: ["stepNum", "hideButtons", "hideEsign", "patient"],
  emits: ["next-step", "previous-step", "hardBounce"],
  data: () => ({
    errorDialog: null,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("instances", ["instance", "instances", "loading"]),
    ...mapState("tasks", ["tasks"]),
    ...mapGetters("patients", ["getHasActiveUploads"]),
    requiredFiles() {
      if (this.hideEsign) return this.patientInstance.files.filter(f => f.required).filter(f => f.type !== "esignature");
      else return this.patientInstance.files.filter(f => f.required);
    },
    eSignatureTasks() {
      return this.tasks?.filter(t => t.code.text === "eSignature") ?? [];
    },
    patientInstance() {
      return this.instances?.find(i => i.id === this.patient.instanceID) ?? this.instance;
    },
  },
  methods: {
    ...mapActions("integrations", [INIT_ESIGNATURE, RESUME_ESIGNATURE]),
    complete() {
      this.$emit("next-step");
    },
    async previousStep() {
      this.$emit("previous-step");
    },
    initSignature(settings) {
      let existingTask = this.eSignatureTasks.find(t => t.focus.identifier.value == settings.templateId);
      if (existingTask) {
        this.RESUME_ESIGNATURE({
          req: {
            taskID: existingTask.id,
            envelopeID: existingTask.identifier[0].value,
            signerClientId: this.patient.id,
            signerEmail: this.user.id,
            signerName: this.user.name,
            returnTo: `r${this.stepNum}`,
          },
          redirect: true,
        });
      } else {
        this.INIT_ESIGNATURE({
          req: {
            ...settings,
            signerClientId: this.patient.id,
            signerEmail: this.user.id,
            signerName: this.user.name,
            returnTo: `r${this.stepNum}`,
          },
          redirect: true,
        });
      }
    },
  },
};
</script>
<style scoped>
.upload-body {
  max-height: 400px;
}
</style>
