<!-- eslint-disable vuetify/no-deprecated-components -->
<template>
  <v-card :disabled="!canEdit" :class="fullscreen ? 'fullscreen' : deleteDialog ? 'delete-dialog' : 'modal-task'" class="elevation-0" :width="calculateWidth" :loading="loading">
    <v-card-title class="font-weight-bold">
      <span v-if="deleteDialog" class="mx-auto">Are you sure?</span>
      <span v-else-if="!task.id">Create Task</span>
      <span v-else>
        <v-breadcrumbs :items="breadCrumbs" class="float-left">
          <template #divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
          <template #item="{ item }">
            <v-chip v-if="item.type == 'chip'" :disabled="item.disabled" :to="item.href" class="cursor-pointer" color="primary" variant="tonal" size="large" @click="item.click">
              {{ item.title }}
              <v-icon icon="mdi-account-outline" end></v-icon>
            </v-chip>
            <v-breadcrumbs-item v-else :disabled="item.disabled" :to="item.href" @click="item.click">{{ item.title }}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </span>
      <span v-if="!deleteDialog" class="float-right">
        <v-chip class="mr-2 mt-3" color="primary" :variant="task.dueDate ? 'tonal' : 'plain'" @click="openDateTimeModal">
          {{ task.dueDate ? formatDate(task.dueDate) + " " + formatTime(task.dueTime) : "Set Due Date" }}
        </v-chip>
        <v-btn v-if="canEdit && showCalling" color="white" variant="text" class="ml-4" @click="$emit('call-patient', task)">
          <v-icon start>mdi-video</v-icon>
          Add Video Call
        </v-btn>
        <v-menu v-if="canEdit && task.id">
          <template #activator="{ props: activatorProps }">
            <v-btn icon v-bind="activatorProps" color="on-surface" variant="text">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="allowDelete" @click="deleteDialog = true">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-select v-model="task.priority" :items="priorities" required variant="plain"
                    class="detail-field float-left pt-0 mt-0" hide-details></v-select> -->
        <template v-if="!fullscreen">
          <!-- <v-btn icon v-if="!fullscreen" variant="text" :to="'tasks/' + task.id ? task.id : 'new'">
                        <v-icon>mdi-fullscreen</v-icon>
                    </v-btn> -->
          <v-btn icon variant="text" color="on-surface" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="router.go(-1)">Cancel</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="save">Save</v-btn>
        </template>
      </span>
    </v-card-title>
    <v-card-text class="overflow-auto pt-0 task-edit-contents">
      <span v-if="deleteDialog">
        <v-alert v-if="taskError" type="error" variant="tonal" class="mb-4">{{ taskError }}</v-alert>
        <v-alert v-else-if="notes?.length > 0" type="error" variant="tonal" class="mb-4">This task has notes. Please delete the notes before deleting the task.</v-alert>
        <v-alert v-else-if="task.id" type="error" variant="tonal" class="mb-4">This will permanently delete the task and all subtasks. Are you sure?</v-alert>
      </span>
      <v-form v-else ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" :md="showDetails ? 5 : 12" class="task-col">
            <v-row class="mb-4">
              <v-col cols="12" md="6">
                <v-text-field v-model="task.description" label="Description" required autofocus variant="solo-filled" type="text"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="assignee" class="detail-field" :items="props.possibleAssignees" item-value="id" item-title="name" type="text" label="Assignee" hide-details variant="solo-filled" clearable @update:model-value="assigneeChange">
                  <template #selection="{ item }">
                    <v-list-item density="compact" class="pa-0" :title="currentAssignee"></v-list-item>
                  </template>
                  <template #prepend-item>
                    <v-list-item>
                      <v-btn block variant="text" @click="showProviderSearch = true">Assign Provider</v-btn>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-if="task.code" v-model="task.code.text" item-title="text" variant="solo-filled" hide-details type="text" :items="taskTypes" class="detail-field" label="Type" @update:model-value="updateTaskCode"></v-select>
              </v-col>
              <v-col v-if="task.basedOn" cols="12" md="6">
                <v-select v-model="task.businessStatus" :items="businessStatuses" label="Business Status" required return-object auto-select-first variant="solo-filled" class="detail-field" item-title="text" hide-details></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="task.status" :items="taskStatuses" item-value="value" item-title="title" label="Status" variant="solo-filled" hide-details @update:model-value="updateTaskStatus"></v-select>
              </v-col>
              <template v-if="!deleteDialog">
                <v-col cols="12" md="6">
                  <v-select v-model="task.basedOn" :items="planList" type="text" label="Based On" hide-details item-title="display" return-object class="detail-field" variant="solo-filled" @update:model-value="planChange"></v-select>
                </v-col>
                <!-- <v-col cols="12">
                  <v-textarea v-if="task.note && task.note.length > 0" v-model="task.note[0].text" placeholder="Task Notes" required class="mb-4" rows="3" type="text" variant="solo-filled"></v-textarea>
                </v-col> -->
                <v-col v-if="false" cols="12" md="6">
                  <v-select v-model="selectedForm" variant="solo-filled" :items="availableForms" item-value="id" item-title="name" label="Requested Form" clearable return-object hide-details @update:model-value="formChange"></v-select>
                  <v-alert v-if="!availableForms.length" density="compact" type="error" class="mt-4">No forms are available for this instance</v-alert>
                </v-col>
                <v-col v-for="(field, index) in customFields" :key="index" cols="12" md="6" class="py-2">
                  <template v-if="field.type === 'string'">
                    <v-text-field :key="index" :model-value="field.value" variant="solo-filled" :label="field.title" @update:model-value="setCustomFieldValue(field, $event)"></v-text-field>
                  </template>
                  <template v-if="field.type === 'boolean'">
                    <v-checkbox :key="index" :true-value="true" :false-value="false" :model-value="field.value" :label="field.title" @update:model-value="setCustomFieldValue(field, $event)"></v-checkbox>
                  </template>
                  <template v-if="field.type === 'date'">
                    <v-text-field :key="index" :model-value="field.value" type="date" :label="field.title" variant="solo-filled" @update:model-value="setCustomFieldValue(field, $event)"></v-text-field>
                  </template>
                  <template v-if="field.type === 'dateTime'">
                    <v-text-field :key="index" :model-value="formatUTCDateTime(field.value)" :label="field.title" variant="solo-filled" @update:model-value="setCustomFieldValue(field, $event)"></v-text-field>
                  </template>
                  <template v-if="field.type === 'integer'">
                    <v-text-field :key="index" :model-value="field.value" :label="field.title" variant="solo-filled" @update:model-value="setCustomFieldValue(field, $event)"></v-text-field>
                  </template>
                </v-col>
              </template>
            </v-row>
            <VAlert v-if="taskError" color="warning" variant="tonal">
              {{ taskError }}
            </VAlert>
          </v-col>
          <v-col v-if="showDetails" class="task-col pt-0 border-s" cols="12" md="7">
            <v-tabs v-model="tab">
              <v-tab value="Subtasks">Subtasks</v-tab>
              <v-tab value="Notes">Notes</v-tab>
              <v-tab value="History">History</v-tab>
              <v-tab v-if="hasQuestionnaire" value="Questionnaire">Questionnaire</v-tab>
              <v-spacer></v-spacer>
              <v-btn v-if="tab == 'Subtasks'" class="d-none toolbar-refresh float-right" variant="text" icon @click="refreshSubtasks">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-tabs>
            <v-tabs-window v-model="tab" class="fill-height">
              <v-tabs-window-item value="Subtasks" class="fill-height">
                <template v-for="(subtask, index) in task.children" :key="index">
                  <v-list-item density="compact" class="sub-task-title pa-0">
                    <template #append>
                      <template v-if="subtask.assigneeID && findUser(subtask.assigneeID)">
                        <Avatar v-tooltip:start="findUser(subtask.assigneeID).name" start size="20px" :user="findUser(subtask.assigneeID)"></Avatar>
                        <!-- <span class="chip-name">{{ renderName(findUser(subtask.assigneeID)) }}</span> -->
                      </template>
                      <Avatar v-else-if="subtask.owner?.display" v-tooltip:start="subtask.owner?.display" start size="20px" :user="subtask.owner"></Avatar>
                      <template v-else-if="subtask.assigneeID">
                        <v-icon size="20">mdi-stethoscope</v-icon>
                      </template>
                      <v-btn v-if="subtask.id" icon variant="plain" size="small" color="grey" @click="editSubtask(subtask)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn v-if="!subtask.id" icon variant="plain" size="small" color="red" @click="removeSubtask(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <template #prepend="{ isActive }">
                      <v-checkbox v-model="subtask.done" @update:model-value="toggleSubTaskStatus(subtask)"></v-checkbox>
                    </template>
                    <template #default>
                      <!-- <span class="cursor-pointer" :class="{ 'text-no-wrap': true, 'text-decoration-line-through': subtask.done }" @click="clickTask(subtask)">{{ subtask.description ? subtask.description : subtask.code ? subtask.code.text : "-" }}</span> -->
                      <v-text-field v-model="subtask.description" placeholder="Description" :class="{ 'text-no-wrap': true, 'text-decoration-line-through': subtask.done }" required hide-details variant="plain" type="text" density="compact" class="task-title" @update:model-value="markSubtaskModified(subtask)"></v-text-field>
                    </template>
                  </v-list-item>
                </template>
              </v-tabs-window-item>
              <v-tabs-window-item value="Notes">
                <Notes :subject="task.for?.reference" :event="`Task/${task.id}`" :notes="notes" :can-edit="canEdit && task.id != null" style="min-height: 400px"></Notes>
              </v-tabs-window-item>
              <v-tabs-window-item value="History">
                <dynamic-resource-table embedded readonly density="compact" resource-type="Task" :query="`Task/${task.id}/_history?`" height="400"></dynamic-resource-table>
              </v-tabs-window-item>
              <v-tabs-window-item value="Questionnaire"></v-tabs-window-item>
            </v-tabs-window>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="!fullscreen">
      <span v-if="task.id && task.authoredOn" v-tooltip:start="formatUTCDateTime(task.authoredOn)" class="text-caption ml-3">Added on: {{ formatUTCDate(task.authoredOn) }}</span>
      <span v-if="automationID" class="text-caption">
        by
        <router-link :to="'/settings/automations/' + automationID">automation</router-link>
      </span>
      <v-spacer></v-spacer>
      <template v-if="deleteDialog">
        <v-btn color="grey" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn :color="loading ? 'on-surface' : 'error'" variant="elevated" :loading="loading" @click="deleteTask(task)">Delete</v-btn>
      </template>
      <template v-else>
        <v-menu v-if="task.id">
          <template #activator="{ props: menuProps }">
            <v-btn color="primary" theme="dark" v-bind="menuProps">
              <template #append>
                <v-icon>mdi-plus</v-icon>
              </template>
              Add
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="addSubTask">
              <v-list-item-title>Subtask</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="!task.id" @click="addNote">
              <v-list-item-title>Note</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="primary" :disabled="!valid" variant="elevated" :prepend-icon="success ? 'mdi-check' : ''" :loading="loading" width="92" @click="save">Save</v-btn>
      </template>
    </v-card-actions>
  </v-card>
  <v-dialog v-model="showProviderSearch">
    <v-card class="provider-search-card min-500">
      <Providers embedded class="min-500" @close-dialog="showProviderSearch = false" @selection-change="selectionChange"></Providers>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn @click="showProviderSearch = false">Cancel</v-btn>
        <v-btn variant="elevated" color="primary" :disabled="!seletedPractitioner" @click="saveProvider">Assign</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dateTimeModal" max-width="850">
    <v-card>
      <v-card-title>
        Due Date and Time
        <v-btn class="float-right" variant="text" @click="clearDateTimeValues">Clear</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-date-picker v-model="tempDueDate" class="mb-4"></v-date-picker>
          </v-col>
          <v-col>
            <v-time-picker v-model="tempDueTime" format="24hr" scrollable></v-time-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="dateTimeModal = false">Cancel</v-btn>
        <v-btn color="primary" variant="elevated" @click="saveDateTimeChanges">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
import { useFhir } from "../../store/fhir/index.js";
import { mapTaskToVM } from "../../utils/fhir-helpers.js";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { toProperCase, formatDate, formatUTCDateTime, formatUTCDate } from "../../utils/helpers";
import * as api from "../../utils/api";
import Providers from "../providers/index.vue";
const store = useStore();
const fhirStore = useFhir();
import { useTasks } from "../../store/tasks";
const taskStore = useTasks();
const workflowPlan = computed(() => {
  return fhirStore?.activePlans.find(p => p.id === task.value?.basedOn?.[0]?.reference?.split("/")[1]);
});
const selectedForm = ref(null);

const emit = defineEmits(["task-update", "close-dialog", "call-patient", "show-task", "task-removed"]);

const closeDialog = () => {
  success.value = false; // Reset success state
  taskError.value = null; // Clear any errors
  emit("close-dialog");
};

const dateTimeModal = ref(false);
const tempDueDate = ref(null);
const tempDueTime = ref(null);
const success = ref(false);

const openDateTimeModal = () => {
  tempDueDate.value = task.value.dueDate ? new Date(task.value.dueDate) : new Date();
  tempDueTime.value = task.value.dueTime || new Date().toTimeString().split(" ")[0].substring(0, 5);
  dateTimeModal.value = true;
};

const calculateWidth = computed(() => {
  if (fullscreen.value) return "100%";
  if (window.innerWidth < 960) return "100%"; // Mobile screens
  return showDetails.value && !deleteDialog.value ? 1200 : 620;
});

const saveDateTimeChanges = () => {
  task.value.dueDate = tempDueDate.value;
  task.value.dueTime = tempDueTime.value;

  // Update restriction.period.end
  if (task.value.dueDate && task.value.dueTime) {
    task.value.restriction = task.value.restriction || {};
    task.value.restriction.period = task.value.restriction.period || {};

    if (task.value.dueDate && task.value.dueTime) {
      const dueDateTime = new Date(`${task.value.dueDate.toISOString().split("T")[0]}T${task.value.dueTime}`);
      if (!isNaN(dueDateTime)) {
        task.value.restriction.period.end = dueDateTime.toISOString();
      } else {
        // Handle invalid time value error
        task.value.restriction.period.end = null;
      }
    } else {
      task.value.restriction.period.end = null;
    }
  }

  dateTimeModal.value = false;
  tempDueDate.value = null;
  tempDueTime.value = null;
};

const props = defineProps({
  task: {
    type: Object,
    default: () => ({
      id: null,
      status: "requested",
      intent: "order",
      selectedForm: null,
      code: {
        text: null,
      },
      businessStatus: null,
      owner: {},
      priority: "routine",
      note: [{ text: null }],
    }),
  },
  subject: {
    type: String,
    default: "",
  },
  subjectName: {
    type: String,
    default: "",
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  embedded: {
    type: Boolean,
    default: false,
  },
  availableForms: {
    type: Array,
    default: () => [],
  },
  allowDelete: {
    type: Boolean,
    default: true,
  },
  possibleAssignees: {
    type: Array,
    default: () => [],
  },
  parentTask: {
    type: Object,
    default: null,
  },
});
let showProviderSearch = ref(false);
let seletedPractitioner = ref(null);
let showDetails = ref(true);
let tab = ref("Notes");
let valid = ref(false);
const originalTask = ref(JSON.stringify(props.task));
const task = ref({ ...props.task });

const hasChanges = computed(() => {
  // Compare current task state with original
  return JSON.stringify(task.value) !== originalTask.value;
});

watch(
  () => props.task,
  newTask => {
    // Create a new object to trigger reactivity
    task.value = { ...newTask };
    if (newTask.children) {
      // Create a new array of children to ensure reactivity
      task.value.children = newTask.children.map(child => ({ ...child }));
    }
    mapTask();
  },
  { deep: true }
);
let subject = ref(props.subject);
let subjectName = ref(props.subjectName);
let assignee = ref(null);
let loading = ref(false);
let taskError = ref(null);
let notes = ref([]);
let deleteDialog = ref(false);

const save = async () => {
  if (loading.value) return; // Prevent multiple saves
  success.value = false;
  taskError.value = null; // Clear any previous errors
  try {
    if (valid.value) {
      loading.value = true;
      // Ensure partOf is not set to the task's own reference
      if (task.value.partOf?.[0].reference === "Task/" + task.value.id) {
        task.value.partOf = null;
      }
      // Save or update subtasks
      if (task.value.children) {
        // Update modified subtasks and create new ones
        const childUpdatePromises = task.value.children
          .filter(subTask => subTask.isModified || !subTask.id) // Only modified or new subtasks
          .map(async subTask => {
            // Ensure partOf is not set to the subtask's own reference
            if (subTask.partOf?.[0].reference === "Task/" + subTask.id) {
              subTask.partOf = null;
            }
            const updatedSubTask = await taskStore.updateTask(subTask);
            return mapTaskToVM(updatedSubTask);
          });

        const updatedChildren = await Promise.all(childUpdatePromises);

        // Create a map for the current children to avoid duplicates by ID
        const childMap = new Map(task.value.children.map(child => [child.id, child]));

        updatedChildren.forEach(updatedChild => {
          if (updatedChild.id) {
            // Replace or add updated subtasks by ID
            childMap.set(updatedChild.id, updatedChild);
          } else if (!task.value.children.some(child => child === updatedChild)) {
            // Add new subtasks without an ID only if they don't already exist in task.value.children
            task.value.children.push(updatedChild);
          }
        });

        // Convert the map back to an array, which will contain only unique tasks by ID
        task.value.children = Array.from(childMap.values()).filter(child => child.id);
      }
      if (task.value.dueDate && task.value.dueTime) {
        try {
          task.value.restriction = task.value.restriction || {};
          task.value.restriction.period = task.value.restriction.period || {};

          // Handle both Date object and string formats for dueDate
          const dateValue = task.value.dueDate instanceof Date ? task.value.dueDate : new Date(task.value.dueDate);

          if (isNaN(dateValue.getTime())) {
            throw new Error("Invalid date value");
          }

          const formattedDate = dateValue.toISOString().split("T")[0];

          // Normalize time format
          let timeValue = task.value.dueTime;
          let hours = 0;
          let minutes = 0;

          // Handle 24-hour format with optional seconds (e.g. "15:48:00" or "15:48")
          const timeMatch = timeValue.match(/^(\d{1,2}):(\d{2})(?::(\d{2}))?$/);

          if (!timeMatch) {
            throw new Error("Invalid time format - must be HH:mm or HH:mm:ss");
          }

          [hours, minutes] = timeMatch.slice(1, 3).map(Number);

          // Validate hours and minutes
          if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            throw new Error("Invalid time values - hours must be 0-23, minutes 0-59");
          }

          // Format to 24-hour HH:mm
          timeValue = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

          const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
          const dateTimeString = `${formattedDate}T${formattedTime}`;
          const dateObj = new Date(dateTimeString);

          if (isNaN(dateObj.getTime())) {
            throw new Error("Invalid date/time combination");
          }

          task.value.restriction.period.end = dateObj.toISOString();
        } catch (error) {
          console.error("Date/time parsing error:", error);
          taskError.value = error.message || "Invalid date or time format";
          loading.value = false;
          return;
        }
      }

      // Create a copy of the task without children for updating
      const taskToUpdate = { ...task.value };
      delete taskToUpdate.children;

      // Update the main task
      const updatedTask = await taskStore.updateTask(taskToUpdate);
      if (!updatedTask || !updatedTask.id || !updatedTask.status) {
        throw new Error("Invalid or incomplete task data");
      }

      // Merge the updated task with the existing task, preserving children
      Object.assign(task.value, updatedTask, { children: task.value.children });
      success.value = emit("task-update", task.value);
      originalTask.value = JSON.stringify(task.value);
      if (props.embedded) {
        store.dispatch("saveTask", task.value);
        router.go(-1);
      } else if (!task.value.id && !taskError.value) {
        emit("close-dialog");
      }
    }
  } catch (error) {
    taskError.value = error.response?.data?.error || error.message;
  } finally {
    loading.value = false;
  }
};
const taskTypes = computed(() => {
  return taskStore?.taskTypes;
});
const fullscreen = computed(() => {
  return router.currentRoute.value.path.includes("tasks/");
});
const hasQuestionnaire = computed(() => {
  return task.value?.focus?.reference?.includes("Questionnaire");
});
const automationID = computed(() => {
  return task.value?.extension?.find(e => e.url === "automation")?.valueString;
});
const showCalling = computed(() => {
  return false;
});

const toggleSubTaskStatus = subTask => {
  let subTaskPlan = fhirStore?.activePlans.find(p => p.id === subTask.basedOn?.[0]?.reference?.split("/")[1]);
  if (subTask.done) {
    subTask.status = "completed";
  } else {
    subTask.status = "requested";
  }
  if (subTaskPlan) {
    //get either the first or last status and update the business status
    if (!subTask.done) {
      subTask.businessStatus = { text: subTaskPlan.action?.[0]?.title, coding: [{ code: subTaskPlan.action?.[0]?.title }] };
    } else {
      subTask.businessStatus = { text: subTaskPlan.action?.[subTaskPlan.action.length - 1]?.title, coding: [{ code: subTaskPlan.action?.[subTaskPlan.action.length - 1]?.title }] };
    }
  }

  markSubtaskModified(subTask);
};

const removeSubtask = index => {
  task.value.children.splice(index, 1);
};

const customFields = computed(
  () =>
    taskStore.getCustomFields.map(field => {
      return {
        ...field,
        value: getCustomFieldValue(field),
        code: field.value,
      };
    }) ?? []
);
const taskStatuses = computed(() => {
  return ["draft", "requested", "received", "rejected", "ready", "cancelled", "in-progress", "on-hold", "failed", "completed", "entered-in-error"].map(s => {
    return { value: s, title: toProperCase(s) };
  });
});

const businessStatuses = computed(() => {
  if (workflowPlan?.value?.action) {
    return workflowPlan?.value?.action.map(a => {
      return { text: a.title, coding: [{ code: a.title }] };
    });
  }
  return ["draft", "requested", "received", "rejected", "ready", "cancelled", "in-progress", "on-hold", "failed", "completed", "entered-in-error"].map(s => {
    return { text: s, coding: [{ code: s }] };
  });
});

const clearDateTimeValues = () => {
  tempDueDate.value = null;
  tempDueTime.value = null;
  task.value.restriction.period.end = null;
  task.value.dueDate = null;
  dateTimeModal.value = false;
};

const formatTime = time => {
  if (!time) return "";
  let [hours, minutes] = time.split(":").map(Number);
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // the hour '0' should be '12'
  minutes = minutes.toString().padStart(2, "0");
  return `${hours}:${minutes} ${ampm}`;
};
onBeforeRouteLeave((to, from, next) => {
  if (hasChanges.value) {
    const answer = window.confirm("You have unsaved changes. Are you sure you want to leave?");
    if (answer) {
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
});

const currentAssignee = computed(() => {
  if (assignee.value == "Patient") {
    return "Patient";
  }
  return task.value?.owner?.display ?? props.possibleAssignees?.find(o => o?.id === assignee.value)?.title;
});
const forText = computed(() => {
  return task.value?.for?.display ?? task.value.for?.reference?.replace("Patient/", "");
});
const breadCrumbs = computed(() => {
  let crumbs = [
    {
      title: forText.value,
      disabled: false,
      type: "chip",
      href: "/patients/" + task.value.for?.reference?.replace("Patient/", ""),
    },
  ];
  //if there is a part of, add it to the crumbs
  if (props.parentTask) {
    crumbs.push({
      title: props.parentTask.code.text,
      disabled: false,
      click: async () => {
        emit("show-task", props.parentTask);
      },
    });
  }
  return crumbs;
});

const assigneeChange = val => {
  if (!val) {
    task.value.owner = null;
    assignee.value = null; // Update the assignee field to null
    return;
  }
  let taskOwner = val == "Patient" ? subjectName.value : props.possibleAssignees?.find(o => o.id === val)?.title;
  if (!taskOwner) {
    taskOwner = val == "Patient" ? "Patient" : "Practitioner/" + val;
  }
  task.value.owner = {
    reference: val == "Patient" ? subject.value : "Practitioner/" + val,
    display: taskOwner,
  };
  assignee.value = val;
};

const deleteTask = async task => {
  loading.value = true;
  let response = await taskStore.deleteTask(task);
  if (taskStore.error || response?.error) {
    store.dispatch("SNACKBAR_ERROR", "An error occurred while deleting the task.");
    loading.value = false;
    return;
  }
  let conflicts = response.data.entry.filter(e => e.response.status.value == "409 Conflict").map(e => e.response.outcome.issue[0].diagnostics.value);
  if (conflicts.length > 0) {
    taskError.value = "The task is currently referenced by at least one other resource and cannot be deleted.";

    loading.value = false;
    return;
  }
  store.dispatch("SNACKBAR_SUCCESS", "Task Deleted");
  emit("close-dialog");
  if (task.id) emit("task-removed", task);
  deleteDialog.value = false;
  loading.value = false;
};

const addSubTask = () => {
  tab.value = "Subtasks";
  showDetails.value = true;
  task.value.children ??= [];
  task.value.children.push({
    id: null,
    status: "requested",
    partOf: [
      {
        reference: task.value.id ? "Task/" + task.value.id : null,
      },
    ],
    intent: "order",
    description: "New Child Task",
    code: {
      text: "Task",
      coding: [{ code: "Task" }],
    },
    priority: "routine",
    note: [{ text: null }],
    resourceType: "Task",
    owner: {},
    for: task.value.for,
    extension: task.value.extension.filter(e => e.url === "patientManagingOrganization"),
    authoredOn: new Date().toISOString(),
    isModified: true, // Mark new subtasks as modified
  });
};

// Add a method to mark subtasks as modified when they are edited
const markSubtaskModified = subtask => {
  subtask.isModified = true;
};

const planList = computed(() =>
  fhirStore?.getPlansByType("workflow").map(p => {
    return { display: p.title, reference: `PlanDefinition/${p.id}` };
  })
);
onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
  mapTask();
  if (taskStore.getCustomFields.length == 0) taskStore.loadCustomFields();
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown);
});

function editSubtask(subtask) {
  // Check if there are actual modifications to the task or its children
  const taskWithoutChildren = { ...task.value };
  delete taskWithoutChildren.children;

  const originalWithoutChildren = { ...JSON.parse(originalTask.value) };
  delete originalWithoutChildren.children;

  const hasActualChanges = task.value.children?.some(child => child.isModified) || JSON.stringify(taskWithoutChildren) !== JSON.stringify(originalWithoutChildren);
  if (hasActualChanges) {
    const answer = window.confirm("You have unsaved changes. Do you want to save before viewing the subtask?");
    if (answer) {
      save().then(() => {
        emit("show-task", subtask);
      });
    } else {
      const proceed = window.confirm("Discard changes and view subtask?");
      if (proceed) {
        emit("show-task", subtask);
      }
    }
  } else {
    emit("show-task", subtask);
  }
}

async function refreshSubtasks() {
  await taskStore.loadSubtasks(task.value);
}

function mapTask() {
  if (!task.value.id) {
    task.value = {
      status: "requested",
      subject: {
        reference: subject.value,
        display: subjectName.value,
      },
      businessStatus: { text: workflowPlan?.value?.action?.[0]?.title, coding: [{ code: workflowPlan?.value?.action?.[0]?.title }] },
      priority: "routine",
      note: [{ text: null }],
      restriction: {
        period: {},
      },
      code: {
        text: "Task",
        coding: [{ code: "Task" }],
      },
      ...task.value,
    };
  } else {
    // Map the dueDate and dueTime from restriction.period
    if (task.value.restriction?.period?.end) {
      const endDate = new Date(task.value.restriction.period.end);
      task.value.dueDate = endDate.toISOString().split("T")[0];
      task.value.dueTime = endDate.toTimeString().split(" ")[0];
    }
  }

  if (task.value?.children?.length > 0) {
    showDetails.value = true;
    tab.value = "Subtasks";
  } else {
    tab.value = "Notes";
  }

  // Ensure children are properly reactive
  if (task.value.children) {
    task.value.children = task.value.children.map(child => ({ ...child, isModified: false }));
  } else {
    task.value.children = [];
  }

  //make sure note is an array
  if (!task.value.note) {
    task.value.note = [{ text: null }];
  }
  if (!task.value.extension) {
    task.value.extension = [];
  }

  if (fhirStore?.getPlansByType("workflow") == null) {
    fhirStore.loadResources("PlanDefinition");
  }

  assignee.value = null;
  if (task.value.owner?.reference?.includes("Practitioner")) {
    assignee.value = task.value.owner?.reference.split("/")[1];
  } else if (task.value.owner?.reference?.includes("Patient")) {
    assignee.value = "Patient";
  }

  if (task?.value?.id) {
    let questionnaireID = task?.value.focus?.reference.split("/")[1];
    selectedForm.value = props.availableForms?.find(f => f.questionnaireID === questionnaireID);
  }
}

function handleKeydown(event) {
  if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
    save();
  }
}
function planChange(val) {
  if (val) {
    let plan = fhirStore?.activePlans.find(p => p.id === val.reference.split("/")[1]);
    task.value.basedOn = [val];
    task.value.businessStatus = { text: plan?.action?.[0]?.title, coding: [{ code: plan?.action?.[0]?.title }] };
  }
}
function updateTaskCode(val) {
  if (val) {
    task.value.code.text = val;
    task.value.code.coding = [{ code: val }];
  }
}
function updateTaskStatus(val) {
  task.value.status = val;
}
function getCustomFieldValue(field) {
  let extension = task.value.extension?.find(e => e.url === field.value);
  if (field.type == "boolean") {
    return extension?.valueBoolean == true;
  } else if (field.type == "date") {
    return extension?.valueDate ?? extension?.valueString;
  } else if (field.type == "dateTime") {
    return extension?.valueDateTime;
  } else if (field.type == "integer") {
    return extension?.valueInteger ?? 0;
  } else {
    return extension?.valueString ?? "";
  }
}
function setCustomFieldValue(field, value) {
  field.value = value;
  let extension = task.value.extension?.find(e => e.url === field.code);
  let addNew = false;
  if (!extension) {
    addNew = true;
    extension = {
      url: field.code,
    };
  }
  if (field.type == "boolean") {
    extension.valueBoolean = value;
  } else if (field.type == "date") {
    extension.valueDate = value;
  } else if (field.type == "dateTime") {
    extension.valueDateTime = value;
  } else if (field.type == "integer") {
    extension.valueInteger = value;
  } else {
    extension.valueString = value;
  }
  if (addNew) {
    task.value.extension.push(extension);
  } else {
    extension = task.value.extension.map(e => {
      if (e.url === field.code) {
        e = extension;
      }
      return e;
    });
    task.value.extension = extension;
  }
}
function addNote() {
  if (!task.value.id) return;
  tab.value = "Notes";
  showDetails.value = true;
}
function saveProvider() {
  if (seletedPractitioner.value) {
    showProviderSearch.value = false;

    task.value.owner = {
      reference: "Practitioner/" + seletedPractitioner.value.id,
      display: seletedPractitioner.value.name?.[0].text,
    };
    assignee.value = seletedPractitioner.value.name?.[0].text;
  }
}
function selectionChange(val) {
  seletedPractitioner.value = val[0];
}
function findUser(id) {
  return props.possibleAssignees?.find(p => p?.id == id);
}
function formChange() {
  task.value.selectedForm = selectedForm.value;

  if (selectedForm.value) {
    task.value.focus = { reference: "Questionnaire/" + selectedForm.value.questionnaireID };
  } else {
    task.value.focus = null;
  }
}
</script>

<style lang="scss" scoped>
.detail-field {
  width: 100%;
  min-width: 200px;
}

.modal-task {
  margin: 0 auto;
  min-height: 600px;
  @media (max-width: 959px) {
    min-height: auto;
    width: 100%;
    margin: 8px;
  }
}

.task-col {
  @media (max-width: 959px) {
    padding: 8px;
  }
}

.border-s {
  @media (max-width: 959px) {
    border-left: none !important;
    border-top: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
    margin-top: 16px;
    padding-top: 16px;
  }
}

.fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
}
#due-date {
  display: flex;
  align-items: center;
  max-width: 300px;
}
</style>
